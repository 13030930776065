<template>
  <v-card
    :ripple="false"
    :hover="false"
    class="data-view-card"
    height="100%"
    @click="showDetail = true"
  >
    <data-view-card-detail
      v-model="showDetail"
      :current-items="currentItems"
      :index="index"
      :get-logo="getLogo"
      :item="item"
    />
    <v-card-text>
      <v-layout justify-start align-center wrap>
        <!-- NUM -->
        <v-layout
          v-if="
            reportLeads.headersChecked.includes('num') ||
            reportLeads.headersChecked.includes('data_hora_registro')
          "
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span
            v-if="reportLeads.headersChecked.includes('num')"
            class="grey--text text--darken-3 font-weight-bold"
          >
            #{{ index }}
          </span>
          <v-layout
            v-if="reportLeads.headersChecked.includes('data_hora_registro')"
            column
          >
            <!-- DATA/HORA -->
            <span class="caption">Data/Hora</span>
            <span class="grey--text text--darken-4">{{
              formatDateRegister(item.data_hora_registro)
            }}</span>
          </v-layout>
        </v-layout>
        <!-- STATUS -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('status')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Status</span>
          <v-chip
            class="caption lighten-3 text--darken-4"
            :class="
              item.status === 'SEM DIRECIONAMENTO'
                ? 'amber amber--text'
                : 'green green--text'
            "
          >
            {{ item.status | noUnderline }}
          </v-chip>
        </v-layout>
        <!-- MOTIVO -->
        <v-layout
          v-if="
            item.status === 'SEM DIRECIONAMENTO' &&
            reportLeads.headersChecked.includes('motivo')
          "
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Motivo</span>
          <v-chip
            v-if="item.motivo && item.motivo === 'sem link'"
            class="caption lighten-4 text--darken-4"
          >
            {{ item.motivo }}
          </v-chip>
          <v-chip
            v-else-if="item.motivo && item.motivo !== 'sem match'"
            class="caption lighten-4 text--darken-4 red--text red"
          >
            {{ item.motivo }}
          </v-chip>
          <v-chip v-else class="caption lighten-4 text--darken-4">
            {{ item.motivo }}
          </v-chip>
        </v-layout>
        <!-- MATCH PARCEIRO -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('match_parceiro')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">{{
            item.status === "SEM DIRECIONAMENTO" ? "Match" : "Parceiro"
          }}</span>
          <v-flex style="height: 46px; min-width: 150px">
            <v-img
              v-if="
                item.match_parceiro !== 'nenhum' &&
                getLogo(item.match_parceiro) !== 'sem-logo'
              "
              height="46"
              max-width="120"
              contain
              :src="getLogo(item.match_parceiro)"
            />
            <span
              v-else-if="item.match_parceiro !== 'nenhum'"
              class="pl-5 grey--text text-uppercase text--darken-3"
              >{{ item.match_parceiro }} - sem logotipo</span
            >
            <span v-if="item.match_parceiro === 'nenhum'">
              <span class="caption pr-3">nenhum</span>
              <v-icon>mdi-circle-off-outline</v-icon>
            </span>
          </v-flex>
        </v-layout>
        <!-- LINK WHATSAPP -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('clicou_link_whatsapp')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Link Whatsapp</span>
          <v-flex>
            <span class="grey--text text--darken-4">{{
              item.clicou_link_whatsapp || "não clicou"
            }}</span>
            <v-icon v-if="item.clicou_link_whatsapp === 'CLICK'"
              >mdi-cursor-default-click</v-icon
            >
          </v-flex>
        </v-layout>
        <!-- DIVIDER #1 -->
        <v-layout v-if="divider1" style="width: 100%" class="pb-3">
          <v-divider></v-divider>
        </v-layout>
        <!-- TIPO EMPRESTIMO -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('tipo_emprestimo')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Tipo</span>
          <span class="grey--text text--darken-4">{{
            item.tipo_emprestimo
          }}</span>
        </v-layout>
        <!-- VALOR EMPRESTIMO -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('valor_emprestimo')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Empréstimo</span>
          <span class="grey--text text--darken-4">{{
            item.valor_emprestimo
              | currency("R$", 2, {
                spaceBetweenAmountAndSymbol: true,
                thousandsSeparator: ".",
                decimalSeparator: ",",
              })
          }}</span>
        </v-layout>
        <!-- VALOR PARCELA -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('valor_parcela')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Parcela</span>
          <span class="grey--text text--darken-4">{{
            item.valor_parcela
              | currency("R$", 2, {
                spaceBetweenAmountAndSymbol: true,
                thousandsSeparator: ".",
                decimalSeparator: ",",
              })
          }}</span>
        </v-layout>
        <!-- DIVIDER #2 -->
        <v-layout v-if="divider2" style="width: 100%" class="pb-3">
          <v-divider></v-divider>
        </v-layout>
        <!-- QTD PERGUNTAS -->
        <v-layout
          v-if="
            reportLeads.headersChecked.includes('total_perguntas_respondidas')
          "
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Qtd Perguntas</span>
          <span class="grey--text text--darken-4">{{
            item.total_perguntas_respondidas
          }}</span>
        </v-layout>
        <!-- DATA NASC -->
        <v-layout
          v-if="item.data_nasc && reportLeads.headersChecked.includes('data_nasc')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Data Nasc.</span>
          <span class="grey--text text--darken-4">{{ item.data_nasc }}</span>
        </v-layout>
        <!-- IDADE -->
        <v-layout
          v-if="item.idade && reportLeads.headersChecked.includes('idade')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Idade</span>
          <span class="grey--text text--darken-4">{{ item.idade }}</span>
        </v-layout>
        <!-- RESTRICAO -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('restricao_spc')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Restrição</span>
          <span class="grey--text text--darken-4">{{
            item.restricao_spc || "não respondido"
          }}</span>
        </v-layout>
        <!-- OCUPACAO -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('ocupacao')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Ocupação</span>
          <span class="grey--text text--darken-4">{{
            item.ocupacao || "não respondido"
          }}</span>
        </v-layout>
        <!-- RENDA -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('renda')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Renda</span>
          <span class="grey--text text--darken-4">{{
            (!item.renda ? 0 : item.renda)
              | currency("R$", 2, {
                spaceBetweenAmountAndSymbol: true,
                thousandsSeparator: ".",
                decimalSeparator: ",",
              })
          }}</span>
        </v-layout>
        <!-- FATURAMENTO -->
        <v-layout
          v-if="item.faturamento_mensal && reportLeads.headersChecked.includes('faturamento')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Faturamento</span>
          <span class="grey--text text--darken-4">{{
            (!item.faturamento_mensal ? 0 : item.faturamento_mensal)
              | currency("R$", 2, {
                spaceBetweenAmountAndSymbol: true,
                thousandsSeparator: ".",
                decimalSeparator: ",",
              })
        }}</span>
        </v-layout>
        <v-layout
          v-else-if="item.faturamento && reportLeads.headersChecked.includes('faturamento')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Faturamento</span>
          <span class="grey--text text--darken-4">{{
            (!item.faturamento ? 0 : item.faturamento)
              | currency("R$", 2, {
                spaceBetweenAmountAndSymbol: true,
                thousandsSeparator: ".",
                decimalSeparator: ",",
              })
          }}</span>
        </v-layout>        
        <!-- DIVIDER #3 -->
        <v-layout v-if="divider3" style="width: 100%" class="pb-3">
          <v-divider></v-divider>
        </v-layout>
        <!-- NOME -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('nome')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Nome</span>
          <span class="grey--text text--darken-4">{{
            item.nome | capitalize
          }}</span>
        </v-layout>
        <!-- EMAIL -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('email')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">E-mail</span>
          <span class="grey--text text--darken-4">{{ item.email }}</span>
        </v-layout>
        <!-- CELULAR -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('celular')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Celular</span>
          <span class="grey--text text--darken-4">{{ item.celular }}</span>
        </v-layout>
        <!-- CPF -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('cpf')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">CPF</span>
          <span class="grey--text text--darken-4">{{ item.cpf }}</span>
        </v-layout>
        <!-- CNPJ -->
        <v-layout
          v-if="item.cnpj && reportLeads.headersChecked.includes('cnpj')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">CNPJ</span>
          <span class="grey--text text--darken-4">{{ item.cnpj }}</span>
        </v-layout>
        <!-- CIDADE/UF -->
        <v-layout
          v-if="
            item.cidade &&
            (reportLeads.headersChecked.includes('cidade') ||
              reportLeads.headersChecked.includes('uf'))
          "
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <v-layout>
            <span
              v-if="
                item.cidade && reportLeads.headersChecked.includes('cidade')
              "
              class="caption"
              >Cidade</span
            >
            <span
              v-if="
                item.cidade &&
                item.uf &&
                reportLeads.headersChecked.includes('cidade') &&
                reportLeads.headersChecked.includes('uf')
              "
              class="caption"
              >/</span
            >
            <span
              v-if="item.uf && reportLeads.headersChecked.includes('uf')"
              class="caption"
              >UF</span
            >
          </v-layout>
          <v-layout>
            <span
              v-if="
                item.cidade && reportLeads.headersChecked.includes('cidade')
              "
              class="grey--text text--darken-4"
              >{{ item.cidade }}</span
            >
            <span
              v-if="
                item.cidade &&
                item.uf &&
                reportLeads.headersChecked.includes('cidade') &&
                reportLeads.headersChecked.includes('uf')
              "
              class="grey--text text--darken-4"
              >-</span
            >
            <span
              v-if="item.uf && reportLeads.headersChecked.includes('uf')"
              class="grey--text text--darken-4"
              >{{ item.uf }}</span
            >
          </v-layout>
        </v-layout>
        <!-- CEP -->
        <v-layout
          v-if="item.cep && reportLeads.headersChecked.includes('cep')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">CEP</span>
          <span class="grey--text text--darken-4">{{ item.cep }}</span>
        </v-layout>
        <!-- DIVIDER #4 -->
        <v-layout v-if="divider4" style="width: 100%" class="pb-3">
          <v-divider></v-divider>
        </v-layout>
        <!-- ULTIMA MSG WHATSAPP -->
        <v-layout
          v-if="reportLeads.headersChecked.includes('whatsapp_ultima_mensagem')"
          column
          class="mr-5 mb-5 flex-grow-0"
        >
          <span class="caption">Última mensagem whatsapp</span>
          <span class="grey--text text--darken-4">{{
            item.whatsapp_ultima_mensagem || "sem mensagem"
          }}</span>
        </v-layout>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import mixins from "@/mixins";
import DataViewCardDetail from "@/components/dashboard/data-view-card-detail";

export default {
  components: { DataViewCardDetail },
  mixins: [mixins],
  props: ["item", "currentItems", "index", "getLogo"],
  data: () => ({
    showDetail: false,
  }),
  computed: {
    reportLeads() {
      return this.$store.state.dashboard.leadReports;
    },
    divider1() {
      return [
        "num",
        "data_hora_registro",
        "status",
        "motivo",
        "match_parceiro",
        "clicou_link_whatsapp",
      ]
        .map((e) => this.reportLeads.headersChecked.includes(e))
        .includes(true);
    },
    divider2() {
      return ["tipo_emprestimo", "valor_emprestimo", "valor_parcela"]
        .map((e) => this.reportLeads.headersChecked.includes(e))
        .includes(true);
    },
    divider3() {
      return [
        "total_perguntas_respondidas",
        "idade",
        "restricao_spc",
        "ocupacao",
        "renda",
      ]
        .map((e) => this.reportLeads.headersChecked.includes(e))
        .includes(true);
    },
    divider4() {
      return ["nome", "email", "celular", "cpf", "cnpj", "cidade"]
        .map((e) => this.reportLeads.headersChecked.includes(e))
        .includes(true);
    },
  },
};
</script>

<style lang="scss">
.data-view-card {
  &.v-card--link {
    &:before {
      background: transparent !important;
    }
    &:focus:before {
      opacity: 1 !important;
      box-shadow: 0 0 10px 0 #00afff8c;
      background: transparent !important;
    }
  }
}
</style>
