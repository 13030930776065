<template>
  <v-card class="data-view-table">
    <data-view-card-detail
      v-model="showDetail"
      :current-items="currentItems"
      :index="currentItems.indexOf(selectedItem)"
      :get-logo="getLogo"
      :item="selectedItem"
    />
    <!-- 

      disable-pagination
        hide-default-footer
     -->
    <!-- this.$store.state.dashboard.leadReports.dataSolicitacoes.requests 
    :search="textSearch"
    -->
    <v-card-text>
      <div
        v-if="
          solicitacoes.length > 0 &&
          solicSearch.totalRequests &&
          this.leadReports.hiddenProgress === true
        "
      >
        <v-data-table
          height="500"
          :headers="headers"
          :header-props="headerProps"
          :items="solicitacoes"
          hide-default-footer
          fixed-header
          :items-per-page="itemsPerPage"
          @current-items="(items) => $emit('change', items)"
          @click:row="
            (item) => {
              this.selectedItem = item;
              this.showDetail = true && !isCheckCliked;
              this.isCheckClicked = false;
            }
          "
        >
          <template #item.solics="{ item }">
            <v-layout justify-center>
              <v-checkbox
                v-model="solicSelecteds"
                :value="item.solicId"
                @click="onAfterClickCheck"
              />
            </v-layout>
          </template>
          <template #item.solicId="{ item }">
            <span
              @mouseover="onMouseOverSolicId(currentItems.indexOf(item))"
              style="position: relative"
            >
              <!-- BUTTON EXPORT -->
              <v-dialog
                max-width="480"
                v-model="showModalExportSolics[currentItems.indexOf(item)]"
                scrollable
                hide-overlay
                persistent
                no-click-animation
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                    small
                    @click="
                      () => {
                        solicSelecteds.push(item.solicId);
                      }
                    "
                    v-show="showBtnExportSolics === currentItems.indexOf(item)"
                    style="position: absolute; z-index: 1; bottom: -30px"
                  >
                    <v-icon small>mdi-file-export</v-icon>
                    <span style="font-size: 9px">exportar</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <div v-if="selectionExportSolicsFinalized" class="mt-4">
                      Solicitações selecionadas
                    </div>
                    <div v-else class="mt-4">
                      Selecione uma ou mais solicitações
                    </div>
                    <div class="mt-4 subtitle-2">
                      <b>{{ solicSelecteds.join(", ") }}</b>
                    </div>
                  </v-card-text>
                  <v-card-text v-if="selectionExportSolicsFinalized">
                    <!-- LIST OPTIONS -->
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-checkbox
                          v-model="optionsExportSolicsSelected"
                          value="csv"
                          label="CSV"
                          prepend-icon="mdi-microsoft-excel"
                        />
                        <v-checkbox
                          v-model="optionsExportSolicsSelected"
                          value="xhtml"
                          label="XHTML"
                          prepend-icon="mdi-file-table"
                        />
                        <v-checkbox
                          v-model="optionsExportSolicsSelected"
                          value="png"
                          label="PNG"
                          prepend-icon="mdi-image"
                        />
                      </v-col>
                      <v-col v-if="optionsExportSolicsSelected.length < 1">
                        <small class="caption">selecione as opções</small>
                      </v-col>
                    </v-row>
                    <span v-if="isExportError" class="capation error--text"
                      >erro ao exportar, tente novamente</span
                    >
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="white"
                      @click="onCancelModalExportSolics(item)"
                      >cancelar</v-btn
                    >
                    <v-btn
                      v-if="!selectionExportSolicsFinalized"
                      color="primary"
                      :disabled="solicSelecteds.length === 0"
                      @click="onFinalizeSelecionExportSolics"
                    >
                      finalizar seleção
                    </v-btn>
                    <v-btn
                      v-else
                      :disabled="optionsExportSolicsSelected.length < 1"
                      color="primary"
                      @click="onExportSolics"
                    >
                      <v-progress-circular
                        v-if="isWating"
                        width="2"
                        class="mr-1"
                        size="20"
                        indeterminate
                      />
                      {{ isWating ? "Exportando aguarde..." : "Exportar" }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              {{ item.solicId }}
            </span>
          </template>
          <template #item.data_hora_registro="{ item }">
            {{ formatDateRegister(item.data_hora_registro) }}
          </template>
          <template #item.nome="{ item }" style="min-width: 200px !important">
            {{ item.nome | capitalize }}
          </template>
          <template #item.valor_emprestimo="{ item }">
            {{
              item.valor_emprestimo
                | currency("R$", 2, {
                  spaceBetweenAmountAndSymbol: true,
                  thousandsSeparator: ".",
                  decimalSeparator: ",",
                })
            }}
          </template>
          <template #item.valor_parcela="{ item }">
            {{
              item.valor_parcela
                | currency("R$", 2, {
                  spaceBetweenAmountAndSymbol: true,
                  thousandsSeparator: ".",
                  decimalSeparator: ",",
                })
            }}
          </template>
          <template #item.match_parceiro="{ item }">
            <v-layout style="height: 36px" justify-center>
              <div v-if="item.match_parceiro !== 'nenhum'">
                <v-img
                  v-if="getLogo(item.match_parceiro) !== 'sem-logo'"
                  height="36"
                  max-width="100"
                  contain
                  :src="getLogo(item.match_parceiro)"
                />
                <span v-else class="text-uppercase caption"
                  >{{ item.match_parceiro }} - sem logotipo</span
                >
              </div>
              <v-layout align-center justify-center v-else>
                <span class="caption pr-3">nenhum</span>
                <v-icon>mdi-circle-off-outline</v-icon>
              </v-layout>
            </v-layout>
          </template>
          <template #item.clicou_link_whatsapp="{ item }">
            <span class="grey--text text--darken-4">{{
              item.clicou_link_whatsapp
            }}</span>
            <v-icon v-if="item.clicou_link_whatsapp === 'CLICK'"
              >mdi-cursor-default-click</v-icon
            >
          </template>
          <template #item.status_op="{ item }">
            <span
              @mouseover="onMouseOver(currentItems.indexOf(item))"
              style="position: relative"
            >
              <!-- BUTTON INCLUIR STATUS MANUAL -->
              <v-dialog
                max-width="480"
                v-model="showModalFeedbackStatus[currentItems.indexOf(item)]"
                scrollable
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                    small
                    @click="() => {}"
                    v-show="showBtnIncludeStatus === currentItems.indexOf(item)"
                    style="position: absolute; z-index: 1; bottom: -30px"
                  >
                    <v-icon small>mdi-file-document-edit</v-icon>
                    <span style="font-size: 9px">incluir status</span>
                  </v-btn>
                </template>
                <!-- FORM FEEDBACK STATUS -->
                <form-feedback-status
                  v-model="showModalFeedbackStatus[currentItems.indexOf(item)]"
                  :item="item"
                  :number="item.solicId"
                />
              </v-dialog>
              <v-chip class="caption lighten-3 text--darken-4 text-uppercase">
                {{ item.status_op | noUnderline }}
              </v-chip>
            </span>
          </template>
          <template #item.status="{ item }">
            <v-chip
              class="caption lighten-3 text--darken-4"
              :class="
                item.status === 'SEM DIRECIONAMENTO'
                  ? 'amber amber--text'
                  : 'green green--text'
              "
            >
              {{ item.status | noUnderline }}
            </v-chip>
          </template>
          <template #item.motivo="{ item }">
            <v-chip
              v-if="item.motivo && item.motivo === 'sem link'"
              class="caption lighten-4 text--darken-4"
            >
              {{ item.motivo }}
            </v-chip>
            <v-chip
              v-else-if="item.motivo && item.motivo !== 'sem match'"
              class="caption lighten-4 text--darken-4 red--text red"
            >
              {{ item.motivo }}
            </v-chip>
            <v-chip
              v-else-if="item.status === 'SEM DIRECIONAMENTO'"
              class="caption lighten-4 text--darken-4"
            >
              {{ item.motivo }}
            </v-chip>
          </template>
          <template #item.restricao_spc="{ item }">
            <span class="grey--text text--darken-4">{{
              item.restricao_spc || "não respondido"
            }}</span>
          </template>
          <template #item.ocupacao="{ item }">
            <span class="grey--text text--darken-4">{{
              item.ocupacao || "não respondido"
            }}</span>
          </template>
          <template #item.renda="{ item }">
            <span class="grey--text text--darken-4">{{
              (!item.renda ? 0 : item.renda)
                | currency("R$", 2, {
                  spaceBetweenAmountAndSymbol: true,
                  thousandsSeparator: ".",
                  decimalSeparator: ",",
                })
            }}</span>
          </template>
          <template #item.faturamento="{ item }">
            <span
              v-if="item.faturamento_mensal"
              class="grey--text text--darken-4"
              >{{
                (!item.faturamento_mensal ? 0 : item.faturamento_mensal)
                  | currency("R$", 2, {
                    spaceBetweenAmountAndSymbol: true,
                    thousandsSeparator: ".",
                    decimalSeparator: ",",
                  })
              }}</span
            >
            <span v-else class="grey--text text--darken-4">{{
              (!item.faturamento ? 0 : item.faturamento)
                | currency("R$", 2, {
                  spaceBetweenAmountAndSymbol: true,
                  thousandsSeparator: ".",
                  decimalSeparator: ",",
                })
            }}</span>
          </template>
        </v-data-table>
      </div>
      <div v-if="this.leadReports.hiddenProgress === false">
        <div class="load-container">
          <v-progress-circular
            :size="60"
            :width="7"
            color="blue"
            indeterminate
          ></v-progress-circular>
          <h1>Aguarde...</h1>
        </div>
      </div>
      <div
        v-if="solicitacoes.length === 0 && leadReports.statusDataSolic === 0"
        class="not-have-requests"
      >
        <h1>Não há solicitações nesta página</h1>
      </div>
      <div class="text-center pt-2" v-if="leadReports.statusDataSolic === 0">
        <v-pagination
          v-model="page"
          :length="Number(leadReports.searchTotalPages)"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import mixins from "@/mixins";
import DataViewCardDetail from "@/components/dashboard/data-view-card-detail";
import FormFeedbackStatus from "@/components/dashboard/form-feedback-status";

export default {
  components: { DataViewCardDetail, FormFeedbackStatus },
  mixins: [mixins],
  props: [
    "currentItems",
    "headers",
    "getLogo",
    "onScroll",
    "solicitacoes",
    "onSearch",
    "onSearchFilter",
  ],
  model: {
    prop: "currentItems",
    event: "change",
  },
  data: () => ({
    headerProps: {
      sortByText: "Ordenar por",
    },
    showDetail: false,
    showBtnIncludeStatus: -1,
    showModalFeedbackStatus: [false],
    showModalExportSolics: [false],
    showBtnExportSolics: -1,
    selectedItem: {},
    page: 1,
    pageCount: 50,
    itemsPerPage: 200,
    isCheckCliked: false,
    solicSelecteds: [],
    optionsExportSolicsSelected: [],
    selectionExportSolicsFinalized: false,
    isWating: false,
    isExportError: false,
  }),
  computed: {
    solicSearch() {
      return this.$store.state.dashboard.leadReports.searchSolicitacao;
    },
    leadReports() {
      return this.$store.state.dashboard.leadReports;
    },
  },
  watch: {
    showModalExportSolics(value) {
      if (value.includes(true)) {
        this.$store.commit("setShowModalExportSolics", true);
        this.$nextTick(() => {
          const el = document.querySelector(
            ".layout-dashboard .v-dialog__content--active"
          );
          if (el && !el.classList.contains("modal-select-solics"))
            el.classList.add("modal-select-solics");
        });
      } else {
        this.$store.commit("setShowModalExportSolics", false);
      }
    },
    page(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.$store.commit(
          "dashboard/leadReports/setSearchSolicitacaoPage",
          newPage
        );
        // verifica se os filtros estão ativos e busca paginando
        // pelos filtros
        if (
          Object.keys(this.leadReports.filterSelected).some(
            (e) => this.leadReports.filterSelected[e]
          )
        ) {
          this.onSearchFilter();

          // se não busca paginando pela busca padrão
        } else {
          this.onSearch();
        }
      }
    },
    "leadReports.searchPage"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.page = newValue;
      }
    },
  },
  methods: {
    onMouseOverSolicId(index) {
      clearInterval(this.timer);
      clearInterval(this.timerHidden);

      this.timer = setTimeout(() => {
        this.showBtnExportSolics = index;
        this.timerHidden = setTimeout(() => {
          this.showBtnExportSolics = -1;
          clearInterval(this.timerHidden);
          clearInterval(this.timer);
        }, 1200);
      }, 400);
    },
    onMouseOver(index) {
      clearInterval(this.timer);
      clearInterval(this.timerHidden);

      this.timer = setTimeout(() => {
        this.showBtnIncludeStatus = index;
        this.timerHidden = setTimeout(() => {
          this.showBtnIncludeStatus = -1;
          clearInterval(this.timerHidden);
          clearInterval(this.timer);
        }, 1200);
      }, 400);
    },
    onAfterClickCheck() {
      this.isCheckCliked = true;
      setTimeout(() => (this.isCheckCliked = false), 400);
    },
    onCancelModalExportSolics(item) {
      this.showModalExportSolics.splice(
        this.currentItems.indexOf(item),
        1,
        false
      );
      this.reset(item);
    },
    onFinalizeSelecionExportSolics() {
      this.selectionExportSolicsFinalized = true;
    },
    onExportSolics(item) {
      if (this.isWating) return;

      this.isWating = true;
      this.isExportError = false;

      this.$store
        .dispatch("dashboard/leadReports/exportMultiSolicitacoesComVeiculo", {
          solicIds: this.solicSelecteds,
          options: this.optionsExportSolicsSelected,
        })
        .then((res) => {
          window.open(
            `${this.$store.state.baseReportsApiURL}${res.url}?filename=${res.filename}`,
            "_blank"
          );
          this.reset(item);
        })
        .catch(() => {
          this.isExportError = true;
          this.isWating = false;
        });
    },
    reset(item) {
      this.showModalExportSolics.splice(
        this.currentItems.indexOf(item),
        1,
        false
      );
      this.solicSelecteds = [];
      this.optionsExportSolicsSelected = [];
      this.selectionExportSolicsFinalized = false;
      this.isWating = false;
      this.isExportError = false;
    },
  },
  mounted() {
    this.onScroll &&
      this.solicitacoes.length > 0 &&
      (document.querySelector(".v-data-table__wrapper").onscroll =
        this.onScroll);
  },
  beforeMount() {
    this.page = this.leadReports.searchPage;
  },
};
</script>

<style lang="scss">
.data-view-table {
  tbody tr {
    cursor: pointer;
    &:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
  // inicia com a coluna 'selecionar' ocultada
  table tr {
    th:nth-child(1),
    td:nth-child(1) {
      display: none;
    }
  }
}
.not-have-requests {
  text-align: center;
}
.not-have-requests h1 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.load-container {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.load-container h1 {
  margin-left: 20px;
  margin-top: 5px;
}
.load-container * {
  display: inline-block;
  min-height: 200px;
}

.modal-select-solics {
  height: auto !important;
  justify-content: center !important;
}
</style>
