<template>
  <v-layout class="dashboard-toolbar" justify-start>
    <v-card class="py-3 px-4 d-flex flex-grow-1">
      <slot />
    </v-card>
  </v-layout>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
