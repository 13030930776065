<template>
  <v-card class="form-feedback-status" :ripple="false" :hover="false">
    <!-- TITLE -->
    <v-card-title>
      Exportar - Solicitação:
      <b class="pl-1" style="color: #444">#{{ number }}</b>
    </v-card-title>
    <!-- CONTENT -->
    <v-card-text>
      <!-- LIST OPTIONS -->
      <v-row no-gutters>
        <v-col cols="12">
          <v-checkbox
            v-model="optionsSelected"
            value="csv"
            label="CSV"
            prepend-icon="mdi-microsoft-excel"
          />
          <v-checkbox
            v-model="optionsSelected"
            value="xhtml"
            label="XHTML"
            prepend-icon="mdi-file-table"
          />
          <v-checkbox
            v-model="optionsSelected"
            value="png"
            label="PNG"
            prepend-icon="mdi-image"
          />
        </v-col>
        <v-col v-if="optionsSelected.length < 1">
          <small class="caption">selecione as opções</small>
        </v-col>
      </v-row>
    </v-card-text>
    <v-layout class="pa-3">
      <v-alert
        :value="!!errorRegisterStatus"
        type="error"
        text
        dense
        transition="scroll-y-transition"
        >{{ errorRegisterStatus }}
      </v-alert>
    </v-layout>
    <!-- ACTIONS -->
    <v-card-actions class="pb-4 px-3">
      <v-layout justify-end>
        <!-- CANCEL -->
        <v-btn small color="white" class="mr-4" @click="onCancel"
          >Cancelar</v-btn
        >
        <!-- SAVE -->
        <v-btn
          :disabled="optionsSelected.length < 1"
          small
          color="primary"
          @click="onExport(item?.tipo_emprestimo)"
        >
          <v-progress-circular
            v-if="isWating"
            width="2"
            class="mr-1"
            size="20"
            indeterminate
          />
          {{ isWating ? "Exportando aguarde..." : "Exportar" }}
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as convertHTMLToXHTML from "../../functions/convertHTMLToXHTML";
import * as SaveAsFile from "../../functions/saveAsFile";

const zip = new window.JSZip();

export default {
  props: ["show", "item", "number"],
  model: {
    prop: "show",
    event: "change",
  },
  data: () => ({
    errorRegisterStatus: "",
    optionsSelected: [],
    asyncCountBlobs: 0,
    isWating: false,
  }),
  mounted() {},
  computed: {},
  watch: {
    asyncCountBlobs(lastValue) {
      if (lastValue > 0 && lastValue === this.optionsSelected.length) {
        this.isWating = false;
        this.generateZIP("solicitacao-" + this.number);
        this.reset();
      }
    },
  },
  methods: {
    reset() {
      this.asyncCountBlobs = 0;
      this.optionsSelected = [];
      this.$emit("change", false);
      this.isWating = false;
    },
    onExport(type) {
      if (this.isWating) return;

      this.isWating = true;

      const getSolicitacoesComVeiculo = () => {
        this.$store
          .dispatch("dashboard/leadReports/getReportSolicitacoesComVeiculo", {
            solicId: this.number,
          })
          .then((data) => {
            // campos selecionados para criar a tabela
            const fields = [
              "nome",
              "data_nascimento",
              "email",
              "celular",
              "cpf",
              "rg",
              "nome_mae",
              "cep",
              "endereco",
              "ocupacao",
              "valor_emprestimo",
              "valor_parcela",
              "renda",
              "garantia",
              "restricao",
              "veiculo_proprio",
              "veiculo_marca",
              "veiculo_ano",
              "veiculo_modelo",
              "veiculo_placa",
              "veiculo_valor",
            ];

            try {
              // obtem a largura máxima para montar o 'width'
              // do SVG
              const width = getTopWidth(data[0]);

              // obtem a quantidade de campos da 1ª coluna e converte em proporção de pixels
              // para montar a altura 'height' do SVG
              const height = fields.length * 21.05;

              const tableHTML = this.createTable(data[0], "veiculo");
              const XHTML = convertHTMLToXHTML.convertTable(tableHTML);

              if (this.optionsSelected.includes("csv")) {
                SaveAsFile.saveAsCSV(
                  "solicitacao-" + this.number,
                  this.createCSVData(data[0], fields),
                  this.optionsSelected.length > 1
                    ? (blob) => {
                        zip.file("solicitacao-" + this.number + ".csv", blob);

                        this.asyncCountBlobs += 1;
                      }
                    : null
                );
              }

              if (this.optionsSelected.includes("xhtml")) {
                SaveAsFile.saveAsXHTML(
                  "solicitacao-" + this.number,
                  XHTML,
                  width,
                  height,
                  this.optionsSelected.length > 1
                    ? (blob) => {
                        zip.file("solicitacao-" + this.number + ".xhtml", blob);

                        this.asyncCountBlobs += 1;
                      }
                    : null
                );
              }

              if (this.optionsSelected.includes("png")) {
                SaveAsFile.saveAsPNG(
                  "solicitacao-" + this.number,
                  XHTML,
                  width,
                  height,
                  this.optionsSelected.length > 1
                    ? (blob) => {
                        zip.file("solicitacao-" + this.number + ".png", blob, {
                          binary: true,
                        });
                        this.asyncCountBlobs += 1;
                      }
                    : null
                );
              }

              this.optionsSelected.length === 1 && this.reset();
            } catch (err) {
              this.isWating = false;
              console.error(err);
            }
          })
          .catch((err) => {
            this.isWating = false;
            console.error(err);
          });
      };

      const getSolicitacoesComImovel = () => {
        this.$store
          .dispatch("dashboard/leadReports/getReportSolicitacoesComImovel", {
            solicId: this.number,
          })
          .then((data) => {
            // campos selecionados para criar a tabela
            const fields = [
              "nome",
              "data_nascimento",
              "email",
              "celular",
              "cpf",
              "rg",
              "nome_mae",
              "cep",
              "endereco",
              "ocupacao",
              "valor_emprestimo",
              "valor_parcela",
              "renda",
              "garantia",
              "restricao",
              "imovel_valor",
              "imovel_numero",
              "imovel_tipo",
              "imovel_valor",
            ];

            try {
              // obtem a largura máxima para montar o 'width'
              // do SVG
              const width = getTopWidth(data[0]);

              // obtem a quantidade de campos da 1ª coluna e converte em proporção de pixels
              // para montar a altura 'height' do SVG
              const height = fields.length * 21.05;

              const tableHTML = this.createTable(data[0], "imovel");
              const XHTML = convertHTMLToXHTML.convertTable(tableHTML);

              if (this.optionsSelected.includes("csv")) {
                SaveAsFile.saveAsCSV(
                  "solicitacao-" + this.number,
                  this.createCSVData(data[0], fields),
                  this.optionsSelected.length > 1
                    ? (blob) => {
                        zip.file("solicitacao-" + this.number + ".csv", blob);

                        this.asyncCountBlobs += 1;
                      }
                    : null
                );
              }

              if (this.optionsSelected.includes("xhtml")) {
                SaveAsFile.saveAsXHTML(
                  "solicitacao-" + this.number,
                  XHTML,
                  width,
                  height,
                  this.optionsSelected.length > 1
                    ? (blob) => {
                        zip.file("solicitacao-" + this.number + ".xhtml", blob);

                        this.asyncCountBlobs += 1;
                      }
                    : null
                );
              }

              if (this.optionsSelected.includes("png")) {
                SaveAsFile.saveAsPNG(
                  "solicitacao-" + this.number,
                  XHTML,
                  width,
                  height,
                  this.optionsSelected.length > 1
                    ? (blob) => {
                        zip.file("solicitacao-" + this.number + ".png", blob, {
                          binary: true,
                        });
                        this.asyncCountBlobs += 1;
                      }
                    : null
                );
              }

              this.optionsSelected.length === 1 && this.reset();
            } catch (err) {
              this.isWating = false;
              console.error(err);
            }
          })
          .catch((err) => {
            this.isWating = false;
            console.error(err);
          });
      };

      type = /Imóvel/g.test(type) ? 'imovel' : /Veículo/g.test(type) ? 'veiculo' : null

      switch (type) {
        case "veiculo":
          getSolicitacoesComVeiculo();
          break;
        case "imovel":
          getSolicitacoesComImovel();
          break;
        default:
          break;
      }

      // função para obter o campo com maior largura
      // e pegar essa largura convertida em pixel
      // para construir o limite de 'width' do SVG
      function getTopWidth(item) {
        return Math.round(
          Object.keys(item).reduce(
            (ac, it) => {
              const col1Len = it?.length ?? 0; // tamanho do texto da 1ª coluna
              const col2Len = item[it]?.length ?? 0; // tamanho do texto da 2ª coluna

              ac.topCol1 = ac.topCol1 < col1Len ? col1Len : ac.topCol1;
              ac.topCol2 = ac.topCol2 < col2Len ? col2Len : ac.topCol2;
              ac.topWidth = (ac.topCol1 + ac.topCol2) * 8.3;
              return ac;
            },
            { topCol1: 0, topCol2: 0, topWidth: 0 }
          ).topWidth
        );
      }
    },
    onCancel() {
      this.reset();
    },
    createTable(data, type) {
      const comImovel = `
            <tr>
              <td>CEP IMÓVEL</td>
              <td>${data.imovel_cep || ""}</td>
            </tr>
            <tr>
              <td>NÚMERO IMÓVEL</td>
              <td>${data.imovel_numero || ""}</td>
            </tr>
            <tr>
              <td>TIPO IMÓVEL</td>
              <td>${data.tipo_imovel || ""}</td>
            </tr>
            <tr>
              <td>VALOR IMÓVEL</td>
              <td>${data.imovel_valor || ""}</td>
            </tr>
      `;

      const comVeiculo = `
            <tr>
              <td>VEÍCULO PRÓPRIO</td>
              <td>${data.veiculo_proprio || ""}</td>
            </tr>
            <tr>
              <td>MARCA</td>
              <td>${data.veiculo_marca || ""}</td>
            </tr>
            <tr>
              <td>ANO</td>
              <td>${data.veiculo_ano || ""}</td>
            </tr>
            <tr>
              <td>MODELO</td>
              <td>${
                data.veiculo_modelo
                  ? this._veiculoModelTranslator(
                      JSON.parse(data.veiculo_modelo)
                    )
                  : ""
              }</td>
            </tr>
            <tr>
              <td>PLACA</td>
              <td>${data.veiculo_placa || ""}</td>
            </tr>
            <tr>
              <td>VALOR</td>
              <td>${
                data.veiculo_valor
                  ? this.$options.filters.currency(
                      data.veiculo_valor,
                      "R$",
                      2,
                      {
                        spaceBetweenAmountAndSymbol: true,
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      }
                    )
                  : ""
              }</td>
            </tr>      
      `;
      return `
            <table>
            <tr>
              <td>NOME</td>
              <td>${data.nome || ""}</td>
            </tr>
            <tr>
              <td>DATA NASCIMENTO</td>
              <td>${data.data_nascimento || ""}</td>
            </tr>
            <tr>
              <td>E-MAIL</td>
              <td>${data.email || ""}</td>
            </tr>
            <tr>
              <td>CELULAR</td>
              <td>${data.celular || ""}</td>
            </tr>
            <tr>
              <td>CPF</td>
              <td>${data.cpf || ""}</td>
            </tr>
            <tr>
              <td>RG</td>
              <td>${data.celular || ""}</td>
            </tr>
            <tr>
              <td>NOME MAE</td>
              <td>${data.nome_mae || ""}</td>
            </tr>
            <tr>
              <td>CEP</td>
              <td>${data.cep || ""}</td>
            </tr>
            <tr>
              <td>ENDEREÇO</td>
              <td>${data.endereco || ""}</td>
            </tr>
            <tr>
              <td>OCUPAÇÃO</td>
              <td>${data.ocupacao || ""}</td>
            </tr>
            <tr>
              <td>VALOR EMPRÉSTIMO</td>
              <td>${
                data.valor_emprestimo
                  ? this.$options.filters.currency(
                      data.valor_emprestimo,
                      "R$",
                      2,
                      {
                        spaceBetweenAmountAndSymbol: true,
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      }
                    )
                  : ""
              }</td>
            </tr>
            <tr>
              <td>VALOR PARCELA</td>
              <td>${
                data.valor_parcela
                  ? this.$options.filters.currency(
                      data.valor_parcela,
                      "R$",
                      2,
                      {
                        spaceBetweenAmountAndSymbol: true,
                        thousandsSeparator: ".",
                        decimalSeparator: ",",
                      }
                    )
                  : ""
              }</td>
            </tr>
            <tr>
              <td>RENDA</td>
              <td>${
                data.renda
                  ? this.$options.filters.currency(data.renda, "R$", 2, {
                      spaceBetweenAmountAndSymbol: true,
                      thousandsSeparator: ".",
                      decimalSeparator: ",",
                    })
                  : ""
              }</td>
            </tr>
            <tr>
              <td>GARANTIA</td>
              <td>${data.garantia || ""}</td>
            </tr>
            <tr>
              <td>RESTRIÇÃO</td>
              <td>${data.restricao || ""}</td>
            </tr>
            ${
              type === "veiculo"
                ? comVeiculo
                : type === "imovel"
                ? comImovel
                : ""
            }
          </table>
      `;
    },
    createCSVData(data, fields) {
      return fields.reduce((grp, it) => {
        if (
          [
            "valor_emprestimo",
            "valor_parcela",
            "renda",
            "veiculo_valor",
            "imovel_valor",
          ].includes(it)
        ) {
          grp[it] = this.$options.filters.currency(data[it], "R$", 2, {
            spaceBetweenAmountAndSymbol: true,
            thousandsSeparator: ".",
            decimalSeparator: ",",
          });
        } else if (it === "veiculo_modelo") {
          grp[it] =
            this._veiculoModelTranslator(JSON.parse(data.veiculo_modelo)) || "";
        } else {
          grp[it] = data[it];
        }

        return grp;
      }, {});
    },
    generateZIP(filename) {
      zip.generateAsync({ type: "blob" }).then((content) => {
        const uriContent = URL.createObjectURL(content);
        const lnkDownload = document.createElement("a");
        lnkDownload.download = filename + ".zip";
        lnkDownload.href = uriContent;
        lnkDownload.click();
      });
    },
    _veiculoModelTranslator(data) {
      return `modelo: ${data?.model || ""} ${data?.modelVersion || ""} ${
        data?.accessoryPackage || ""
      }, num. portas: ${data?.numberOfDoors || "nao informado"}, combustível: ${
        data?.fuelType || "nao informado"
      }`;
    },
  },
};
</script>

<style scoped></style>
