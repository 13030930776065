<template>
  <v-card class="form-feedback-status" :ripple="false" :hover="false">
    <!-- TITLE -->
    <v-card-title> Incluir Status - Solicitação: <b class="pl-1" style="color: #444">#{{ number }}</b> </v-card-title>
    <!-- CONTENT -->
    <v-card-text>
      <!-- LIST OPTION STATUS -->
      <v-row no-gutters>
        <v-col cols="12" v-for="(item, i) in getListStatus" :key="i">
          <!-- OPTION STATUS -->
          <v-radio-group v-model="statusSelected">
            <v-radio
              small
              :label="item.desc ? item.desc.toUpperCase() : ''"
              :value="item.value"
              @click="onSelectOption(i)"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-layout justify-space-between>
        <!-- BTN SHOW MORE -->
        <div>
          <v-btn
            v-if="countOthersStatus > 0"
            text
            x-small
            color="primary"
            @click="onShowMoreStatus"
          >
            {{ labelBtnShowMoreStatus }}
          </v-btn>
        </div>
        <!-- ADD STATUS FORM DIALOG -->
        <v-dialog
          scrollable
          v-model="showFormAddStatus"
          persistent
          max-width="480"
        >
          <!-- BTN SHOW FORM -->
          <template #activator="{ on, attrs }">
            <v-btn
              text
              color="green"
              v-on="on"
              v-bind="attrs"
              x-small
              @click="() => {}"
            >
              <v-icon small>mdi-plus</v-icon>
              <span>criar novo status</span>
            </v-btn>
          </template>
          <v-card height="100%">
            <v-card-title>
              Novo Status
              <v-spacer></v-spacer>
              <!-- BUTTON CLOSE -->
              <v-btn icon @click="onCloseFormAddStatus">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <!-- CONTENT -->
            <v-card-text>
              <!-- FORM -->
              <v-form>
                <v-text-field
                  v-model.trim="descNewStatus"
                  label="nome do status"
                  @input="descNewStatus = descNewStatus.toUpperCase()"
                  ref="refInputNomeStatus"
                />
              </v-form>
            </v-card-text>
            <!-- ACTIONS -->
            <v-card-actions class="pb-4 px-3">
              <v-layout justify-space-between>
                <!-- CANCEL -->
                <v-btn
                  small
                  color="white"
                  class="mr-4"
                  @click="onCloseFormAddStatus"
                >
                  Cancelar
                </v-btn>
                <!-- SAVE -->
                <v-btn small color="success" @click="onAddStatus">
                  Adicionar
                </v-btn>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <!-- COMMENT AREA -->
      <v-textarea
        v-model="comment"
        :label="
          !statusSelected
            ? 'selecione uma opção de status acima'
            : 'escreva um comentário'
        "
        class="mt-10"
        :disabled="!statusSelected"
        ref="refComentarioStatus"
      />
    </v-card-text>
    <v-layout class="pa-3">
      <v-alert
        :value="!!errorRegisterStatus"
        type="error"
        text
        dense
        transition="scroll-y-transition"
        >{{ errorRegisterStatus }}
      </v-alert>
    </v-layout>
    <!-- ACTIONS -->
    <v-card-actions class="pb-4 px-3">
      <v-layout justify-space-between>
        <!-- CANCEL -->
        <v-btn small color="white" class="mr-4" @click="onCancel"
          >Cancelar</v-btn
        >
        <!-- SAVE -->
        <v-btn small color="primary" @click="onSave">Salvar</v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["show", "item", "number"],
  model: {
    prop: "show",
    event: "change",
  },
  data: () => ({
    showFormAddStatus: false,
    statusSelected: null,
    showItemsLimit: 4,
    showMore: false,
    listStatus: [
      { desc: "Aprovado", value: "APROVADO", first: 0 },
      { desc: "Em Análise", value: "EM_ANALISE", first: 0 },
      { desc: "Negado", value: "NEGADO", first: 0 },
      {
        desc: "Aguardando Documentos",
        value: "AGUARDANDO_DOCUMENTOS",
        first: 0,
      },
    ],
    comment: "",
    statusName: "",
    descNewStatus: "",
    errorRegisterStatus: "",
  }),
  watch: {
    showFormAddStatus(isShow) {
      if (isShow) {
        setTimeout(() => this.$refs.refInputNomeStatus.focus(), 400);
      }
    },
  },
  mounted() {
    this.$store
      .dispatch("database/solicStatus/getAllStatusManual")
      .then((data) => {
        data.forEach((item) => {
          if (!this.listStatus.some((e) => e.value === item.nome)) {
            this.listStatus.push({
              desc: item.descricaoLead,
              value: item.nome.toUpperCase(),
              first: 0,
            });
          }
        });
      });
  },
  computed: {
    getListStatus() {
      return this.listStatus.slice(0, this.showItemsLimit);
    },
    labelBtnShowMoreStatus() {
      return this.showMore
        ? "exibir menos status"
        : `exibir mais ${this.countOthersStatus} status`;
    },
    countOthersStatus() {
      return this.listStatus.length - this.showItemsLimit;
    },
  },
  methods: {
    limitShowStatus() {
      this.showItemsLimit = 4;
    },
    reset() {
      this.showFormAddStatus = false;
      this.statusSelected = null;
      this.showItemsLimit = 4;
      this.showMore = false;
      this.$emit("change", false);
      this.comment = '';
    },
    onSave() {
      this.errorRegisterStatus = "";
      this.$store
        .dispatch("database/solicStatus/postSolicitacaoStatusManual", {
          idSolicitacao: this.item.solicId,
          nomeStatus: this.statusSelected,
          comentarioStatus: this.comment,
          descricaoLead: this.listStatus.find(
            (e) => e.value === this.statusSelected
          ).desc
        })
        .then(() => {
          // muda o status_op sem precisar recarregar a página
          this.item.status_op = this.listStatus.find(
            (e) => e.value === this.statusSelected
          ).desc;
          // muda o comentario_op sem precisar recarregar a página
          this.item.comentario_op = this.comment

          // atualiza a timeline historico de status operacionais
          this.$store
          .dispatch("dashboard/leadReports/getAllStatusOp", {
            solicId: this.item.solicId,
          })
          
          this.$store.commit('setEmitEventHomeOnSearch', true);
          this.reset();
        })
        .catch(() => {
          this.errorRegisterStatus = "Erro ao tentar salvar o status.";
        });
    },
    onCancel() {
      this.reset();
    },
    onCloseFormAddStatus() {
      this.showFormAddStatus = false;
      this.statusName = this.descNewStatus;
      this.descNewStatus = "";
    },
    onShowMoreStatus() {
      if (this.showMore) {
        this.limitShowStatus();
        this.showMore = false;
      } else {
        this.showItemsLimit = this.listStatus.length;
        this.showMore = this.countOthersStatus > this.showItemsLimit;
      }
    },
    onAddStatus() {
      this.listStatus.splice(0, 0, {
        desc: this.descNewStatus && this.descNewStatus.toUpperCase(),
        value: this.toNFDText(this.descNewStatus).replace(/\s/g, "_"),
        first: 0,
      });
      this.limitShowStatus();
      this.onCloseFormAddStatus();
      this.statusSelected = null;
    },
    onSelectOption(index) {
      this.showItemsLimit = 1;
      this.listStatus.map((item) => (item.first = 0));
      this.listStatus[index].first = 1;
      this.listStatus.sort((a, b) => b.first - a.first);
      setTimeout(() => this.$refs.refComentarioStatus.focus(), 100);
    },
    toNFDText(text) {
      return text
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\W/g, " ");
    },
  },
};
</script>

<style scoped>
</style>
