<template>
  <v-layout class="filter-panel" justify-start v-hotkey="keymap">
    <v-card class="py-3 px-2 d-flex flex-grow-1">
      <v-card-text>
        <v-layout justify-end>
          <v-btn
            x-small
            text
            class="grey--text text--darken-1"
            @click="$store.commit('dashboard/leadReports/setShowFilter', false)"
          >
            <v-icon x-small class="mr-1">mdi-close</v-icon>
            fechar
          </v-btn>
        </v-layout>
        <v-layout align-center>
          <v-form @submit.prevent="_onSearch">
            <v-row>
              <v-col sm="4">
                <v-row>
                  <v-col sm="12">
                    Filtrar por data
                    <v-switch
                      v-model="reportLeads.searchCustomDate"
                      class="pl-1"
                      label="personalizar a data"
                    />
                  </v-col>
                  <v-col sm="12">
                    <v-row>
                      <v-col sm="6">
                        <v-text-field
                          type="date"
                          v-model="reportLeads.searchSolicitacao.startDate"
                          label="Data inicial"
                          dense
                          outlined
                          :disabled="!reportLeads.searchCustomDate"
                        />
                      </v-col>
                      <v-col sm="6">
                        <v-text-field
                          type="date"
                          v-model="reportLeads.searchSolicitacao.endDate"
                          label="Data final"
                          dense
                          outlined
                          :disabled="!reportLeads.searchCustomDate"
                        />
                      </v-col>
                      <v-col cols="12">
                        Resultados obtidos na perspectiva de
                        <b
                          >{{
                            getDiffDaysBetweenDates(
                              this.reportLeads.searchSolicitacao.startDate,
                              this.reportLeads.searchSolicitacao.endDate
                            )
                          }}
                          dias
                        </b>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical class="mx-3"></v-divider>
              <v-col>
                <v-row>
                  <v-col sm="12">Filtrar por campos</v-col>

                  <v-col sm="12">
                    <v-row>
                      <v-col sm="12">
                        <v-text-field
                          v-model="searchFormFilter.nome"
                          type="text"
                          label="Nome"
                          dense
                          outlined
                          ref="filterFieldNomeRef"
                          @focus="onLastFocus('filterFieldNomeRef')"
                        />
                      </v-col>
                      <v-col sm="4">
                        <v-text-field
                          v-model="searchFormFilter.cpf"
                          type="text"
                          label="CPF"
                          dense
                          outlined
                          v-mask="['###.###.###-##']"
                          ref="filterFieldCpfRef"
                          @focus="onLastFocus('filterFieldCpfRef')"
                        />
                      </v-col>
                      <v-col sm="4">
                        <v-text-field
                          v-model="searchFormFilter.cnpj"
                          type="text"
                          label="CNPJ"
                          dense
                          outlined
                          v-mask="['##.###.###/####-##']"
                          ref="filterFieldCnpjRef"
                          @focus="onLastFocus('filterFieldCnpjRef')"
                        />
                      </v-col>
                      <v-col sm="4">
                        <v-text-field
                          v-model="searchFormFilter.email"
                          type="text"
                          label="E-mail"
                          dense
                          outlined
                          ref="filterFieldEmailRef"
                          @focus="onLastFocus('filterFieldEmailRef')"
                        />
                      </v-col>
                      <v-col sm="4">
                        <v-text-field
                          v-model="searchFormFilter.celular"
                          type="tel"
                          label="Celular"
                          dense
                          outlined
                          v-mask="['(##) ####-####', '(##) #####-####']"
                          ref="filterFieldCelularRef"
                          @focus="onLastFocus('filterFieldCelularRef')"
                        />
                      </v-col>
                      <v-col sm="6">
                        <v-text-field
                          v-model="searchFormFilter.cidade"
                          type="text"
                          label="Cidade"
                          dense
                          outlined
                          ref="filterFieldCidadeRef"
                          @focus="onLastFocus('filterFieldCidadeRef')"
                        />
                      </v-col>
                      <v-col sm="2">
                        <v-text-field
                          v-model="searchFormFilter.uf"
                          type="text"
                          label="UF"
                          dense
                          outlined
                          maxLength="2"
                          class="text-uppercase"
                          ref="filterFieldUfRef"
                          @focus="onLastFocus('filterFieldUfRef')"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col sm="12">
                <v-btn class="mr-4" type="submit" color="primary">
                  <v-icon small class="mr-2">mdi-magnify</v-icon>
                  Buscar
                </v-btn>
                <v-btn color="white" @click="onClear"> Limpar </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
import mixins from "@/mixins";

export default {
  props: ["onSearch"],
  mixins: [mixins],
  computed: {
    reportLeads() {
      return this.$store.state.dashboard.leadReports;
    },
    searchFormFilter() {
      return this.reportLeads.searchFormFilter;
    },
    keymap() {
      return {};
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.reportLeads.searchFilterFieldLastFocus)
        this.$refs[this.reportLeads.searchFilterFieldLastFocus].focus();
      else this.$refs["filterFieldNomeRef"].focus();
    }, 400);
  },
  methods: {
    onLastFocus(ref) {
      this.$store.commit(
        "dashboard/leadReports/setSearchFilterFieldLastFocus",
        ref
      );
    },
    onClear() {
      this.$store.commit("dashboard/leadReports/setSearchFormFilter", {});
      // this.$store.commit("dashboard/leadReports/setSearchSolicitacao", {
      //   startDate: this.todayDateForFilter,
      //   endDate: this.todayDateForFilter,
      // });
      this.$store.commit("dashboard/leadReports/setSearchSolicitacaoPage", 1);
      this.$store.commit("dashboard/leadReports/setDayFilterClicked", 0);
      this.onSearch(this.searchFormFilter);
    },
    _onSearch() {
      this.$store.commit("dashboard/leadReports/setDayFilterClicked", 0);
      this.$store.commit("dashboard/leadReports/setHiddenProgress", false); // exibir a barra de progresso
      this.onSearch(this.searchFormFilter);
    },
  },
};
</script>

<style lang="scss">
.filter-panel {
  input {
    text-transform: inherit;
  }
}
</style>
