<template>
  <div class="home grey lighten-5 fill-height" v-hotkey="keymap">
    <!-- TO TOP FAB -->
    <transition name="slide-x-reverse-transition">
      <v-btn
        v-show="showFab"
        fab
        elevation="1"
        fixed
        right
        bottom
        color="blue lighten-2"
        dark
        @click="onClickTopFab"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </transition>
    <!-- PAGE TITLE -->
    <v-card-title class="text-h4">
      <v-layout justify-space-between>
        {{ title }}
        <!-- SINGLE PAGINATOR -->
        <div class="mr-2" v-if="!reportLeads.searchActive">
          <single-paginator />
        </div>
      </v-layout>
    </v-card-title>
    <!-- PAGE CONTAINER -->
    <v-card-text
      class="page-container"
      :style="
        (isFullScreen
          ? 'height: calc(100vh - 103px);'
          : 'height: calc(100vh - 80px);') + ' overflow-y: auto;'
      "
    >
      <v-row>
        <v-col>
          <!-- TOP ROW -->
          <v-row>
            <!-- TOOLBAR COL -->
            <v-col sm="12" class="pb-0 mt-1">
              <!-- TOOLBAR -->
              <dashboard-toolbar>
                <v-layout align-center>
                  <!-- RELATORIOS -->
                  <toolbar-section title="relatórios">
                    <!-- EXCEL -->
                    <export-report-button
                      icon="mdi-microsoft-excel"
                      icon-color="green--text text--darken-3"
                      :on-click="this.onReportExcel"
                      :status="reportLeads.statusExcelReportBasic"
                    />
                  </toolbar-section>
                  <!-- GROUP -->
                  <v-layout justify-start align-center class="flex-grow-0">
                    <!-- DIVIDER -->
                    <v-divider vertical class="mx-3"></v-divider>
                    <!-- FILTROS -->
                    <toolbar-section title="filtros">
                      <!-- OPEN FILTER PANEL -->
                      <simple-toolbar-button
                        icon="mdi-filter"
                        :on-click="onToggleFilter"
                        :condition-show-badge="reportLeads.searchActive"
                        :color="reportLeads.showFilter ? 'blue' : ''"
                        title="CTRL+M"
                      />
                      <!-- OPEN MODAL FILTER COLUMNS -->
                      <select-columns-toolbar-button
                        :on-change="onToggleCheckHeader"
                        :on-add-all-headers-checked="addAllHeadersChecked"
                        :on-remove-all-headers-checked="removeAllHeadersChecked"
                        :headers="getHeaders()"
                      />
                    </toolbar-section>
                    <!-- DIVIDER -->
                    <v-divider vertical class="mx-3"></v-divider>
                    <!-- VISUALIZAÇÃO -->
                    <toolbar-section title="visualização">
                      <!-- 1 COLUNA -->
                      <simple-toolbar-button
                        title="1 coluna"
                        icon="mdi-view-stream"
                        :color="reportLeads.showType === 12 ? 'blue' : ''"
                        :on-click="
                          () =>
                            $store.commit(
                              'dashboard/leadReports/setShowType',
                              12
                            )
                        "
                      />
                      <!-- 2 COLUNAS -->
                      <simple-toolbar-button
                        style="transform: translateX(1px) rotateZ(90deg)"
                        title="2 colunas"
                        icon="mdi-view-stream"
                        :color="reportLeads.showType === 6 ? 'blue' : ''"
                        :on-click="
                          () =>
                            $store.commit(
                              'dashboard/leadReports/setShowType',
                              6
                            )
                        "
                      />
                      <!-- 3 COLUNAS -->
                      <simple-toolbar-button
                        title="3 colunas"
                        icon="mdi-view-column"
                        :on-click="
                          () =>
                            $store.commit(
                              'dashboard/leadReports/setShowType',
                              4
                            )
                        "
                        :color="reportLeads.showType === 4 ? 'blue' : ''"
                      />
                      <!-- 4 COLUNAS -->
                      <simple-toolbar-button
                        title="4 colunas"
                        icon="mdi-view-comfy"
                        :on-click="
                          () =>
                            $store.commit(
                              'dashboard/leadReports/setShowType',
                              3
                            )
                        "
                        :color="reportLeads.showType === 3 ? 'blue' : ''"
                      />
                      <!-- TABELA -->
                      <simple-toolbar-button
                        title="tabela"
                        icon="mdi-table-large"
                        :on-click="
                          () =>
                            $store.commit(
                              'dashboard/leadReports/setShowType',
                              0
                            )
                        "
                        :color="reportLeads.showType === 0 ? 'blue' : ''"
                      />
                    </toolbar-section>
                  </v-layout>
                </v-layout>
              </dashboard-toolbar>
            </v-col>
            <!-- FILTER PANEL COL -->
            <v-col v-if="reportLeads.showFilter" sm="12" class="pb-0">
              <!-- FILTER PANEL -->
              <filter-panel :on-search="onSearch" />
            </v-col>
            <!-- WIDGETS COL -->
            <v-col sm="12">
              <v-layout>
                <!-- LEADS -->
                <widget-total
                  color="indigo lighten-2"
                  icon="mdi-account"
                  title="Leads"
                  :value="reportLeads.totalLeadsRegistered"
                />
                <!-- SOLICITACOES
                // antigamente podia cliacar para mostrar todas as solicitações mas hoje é inviável
                :on-click="onClickSolicitacoes"
                -->
                <widget-total
                  color="blue lighten-2"
                  icon="mdi-swap-horizontal-bold"
                  title="Solicitações"
                >
                  <template #value>
                    <v-card-text
                      v-if="reportLeads.totalSolicitacoes > 0"
                      style="min-width: 170px; max-width: 150px"
                      class="pa-1 pt-0 headline"
                    >
                      {{ reportLeads.totalSolicitacoes }} /
                      <small>{{
                        reportLeads.totalSolicitacoesRegistered
                      }}</small>
                    </v-card-text>
                    <v-card-text
                      v-else
                      style="min-width: 120px; max-width: 140px"
                      class="pa-1 pt-0 headline"
                    >
                      {{ reportLeads.totalSolicitacoesRegistered }}
                    </v-card-text>
                  </template>
                </widget-total>
                <!-- HOJE -->
                <widget-total
                  :card-color="
                    reportLeads.dayFilterClicked === 1 ? 'yellow lighten-3' : ''
                  "
                  :color="
                    reportLeads.dayFilterClicked === 1
                      ? 'white'
                      : 'amber lighten-2'
                  "
                  :icon-color="
                    reportLeads.dayFilterClicked === 1 ? 'amber lighten-1' : ''
                  "
                  icon="mdi-swap-horizontal-bold"
                  title="Hoje"
                  :value="reportLeads.totalSolicitacoesToday"
                  :on-click="onClickToday"
                />
                <!-- ONTEM -->
                <widget-total
                  :card-color="
                    reportLeads.dayFilterClicked === 2 ? 'green lighten-3' : ''
                  "
                  :color="
                    reportLeads.dayFilterClicked === 2
                      ? 'white'
                      : 'green lighten-2'
                  "
                  :icon-color="
                    reportLeads.dayFilterClicked === 2 ? 'green lighten-2' : ''
                  "
                  icon="mdi-swap-horizontal-bold"
                  title="Ontem"
                  :value="reportLeads.totalSolicitacoesYesterday"
                  :on-click="onClickYesterday"
                />
                <!-- ULTIMOS 5 DIAS -->
                <widget-total
                  :card-color="
                    reportLeads.dayFilterClicked === 3 ? 'orange accent-1' : ''
                  "
                  :color="
                    reportLeads.dayFilterClicked === 3
                      ? 'white'
                      : 'orange lighten-2'
                  "
                  :icon-color="
                    reportLeads.dayFilterClicked === 3 ? 'orange lighten-2' : ''
                  "
                  icon="mdi-swap-horizontal-bold"
                  title="Últimos 5 Dias"
                  :value="reportLeads.totalSolicitacoesLast5Days"
                  :on-click="onClickLast5Days"
                />
              </v-layout>
            </v-col>
            <!-- TEXT SEARCH PANEL COL -->
            <v-col sm="12">
              <!-- TEXT SEARCH PANEL -->
              <text-search-panel
                :current-items="currentItems"
                :onSearch="onSearch"
                title="CTRL+B"
              />
              <!-- NOT FOUND TEXT SEARCH -->
              <v-card
                v-if="textSearch && currentItems.length === 0"
                min-height="400"
                class="mt-3"
              >
                <v-card-text> Nada encontrado. </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- BOTTOM ROW -->
          <v-row>
            <!-- PROGRESS BAR COL -->
            <v-col
              cols="12"
              v-if="showProgressbar && !reportLeads.hiddenProgress"
            >
              <!-- PROGRESS BAR -->
              <small>carregando...</small>
              <v-progress-linear
                color="blue"
                indeterminate
                rounded
                height="6"
              />
            </v-col>
            <v-col sm="12" class="pt-0 text-right d-flex justify-end">
              <!-- <span class="grey--text text--darken-3"
                >Resultados obtidos na perspectiva de
                <b
                  >{{
                    getDiffDaysBetweenDates(
                      this.reportLeads.searchSolicitacao.startDate,
                      this.reportLeads.searchSolicitacao.endDate
                    )
                  }}
                  dias
                </b>
              </span> -->
              <span class="grey--text text--darken-3"
                >última atualização: {{ lastUpdate.toLocaleString() }}
              </span>
            </v-col>
            <v-col cols="12">
              <div class="pb-4">Filtros agrupados</div>
              <div class="py-1">
                <v-btn
                  x-small
                  text
                  color="primary"
                  @click="onToggleSelectFilters"
                  title="CTRL+F"
                >
                  <v-icon x-small v-if="showSelectedFilters">mdi-close</v-icon>
                  <v-icon x-small v-else>mdi-filter</v-icon>
                  <small
                    class="pl-2"
                    style="font-size: 11px; text-transform: lowercase"
                    >{{ showSelectedFilters ? "fechar" : "selecionar filtros" }}
                  </small>
                </v-btn>
                <v-btn
                  v-if="!showSelectedFilters"
                  x-small
                  text
                  color="primary"
                  @click="onClearFilters"
                >
                  <v-icon x-small>mdi-trash-can</v-icon>
                  <small
                    class="pl-1"
                    style="font-size: 11px; text-transform: lowercase"
                    >limpar filtros
                  </small>
                </v-btn>
              </div>
              <div
                v-show="
                  showSelectedFilters && reportLeads.statusDataSolic === 0
                "
                style="height: 322px; overflow: hidden auto"
                class="grey lighten-4 px-4"
                ref="containerSelectFilters"
              >
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col
                        v-for="(i, index) in Object.keys(
                          this.reportLeads.filterSelected
                        )"
                        :key="index"
                        :cols="getColByFilter(i)"
                      >
                        <v-autocomplete
                          v-model="reportLeads.filterSelected[i]"
                          :label="i.replace(/_/g, ' ').toUpperCase()"
                          :items="reportLeads.filtrosAgrupados[i]"
                          :item-text="i"
                          :item-value="i"
                          :loading="reportLeads.statusFiltrosAgrupados === i"
                          @change="onSelectFilter"
                          @keypress.ctrl.shift.f="onApplyFilter"
                          @focus="
                            onSelectedFilerFocus(`autocompleteRef_${index}`)
                          "
                          :ref="`autocompleteRef_${index}`"
                        >
                          <template #selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="removeFilterItemSelected(i)"
                              color="indigo lighten-1 white--text"
                            >
                              <b>{{ data.item.qtd }} - {{ data.item[i] }}</b>
                            </v-chip>
                          </template>
                          <template #item="data">
                            <template v-if="typeof data.item === 'object'">
                              <v-list-item-content v-if="i === 'uf'">
                                <v-row>
                                  <v-col cols="3">{{ data.item.qtd }} </v-col>
                                  <v-col cols="1">&nbsp;-</v-col>
                                  <v-col cols="7">{{ data.item[i] }}</v-col>
                                </v-row>
                              </v-list-item-content>
                              <v-list-item-content v-else>
                                <v-row>
                                  <v-col cols="2">{{ data.item.qtd }} </v-col>
                                  <v-col cols="1">&nbsp;-</v-col>
                                  <v-col cols="9">{{ data.item[i] }}</v-col>
                                </v-row>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <v-col
                cols="12"
                v-show="
                  showSelectedFilters && reportLeads.statusDataSolic === 0
                "
                class="pt-0 grey lighten-4"
              >
                <v-row>
                  <v-col cols="1">
                    <v-btn small color="primary" @click="onApplyFilter"
                      >Aplicar</v-btn
                    >
                  </v-col>
                  <v-col v-if="showSelectedFilters">
                    <v-btn small color="white" @click="onClearFilters"
                      >limpar</v-btn
                    >
                  </v-col>
                  <v-col cols="12" v-if="!resultsByFilters">
                    <small
                      ><i
                        >clique em 'aplicar' para filtrar os resultados
                        novamente ou use o atalho CTRL+SHIT+F</i
                      ></small
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
            <!-- DATA ITEMS IN VIEW CARD COL -->
            <v-col v-if="reportLeads.showType > 0 && currentItems.length > 0">
              <v-row>
                <!-- DATA ITEMS IN VIEW CARD -->
                <v-col
                  v-for="(item, i) in solicitacoes || currentItems"
                  :key="i"
                  :sm="reportLeads.showType"
                >
                  <!-- $store.state.dashboard.leadReports.dataSolicitacoes.requests -->
                  <!-- DATA VIEW CARD -->
                  <data-view-card
                    :item="item"
                    :index="item.solicId"
                    :current-items="solicitacoes || currentItems"
                    :get-logo="getLogo"
                  />
                </v-col>
              </v-row>
            </v-col>
            <!-- DATA VIEW TABLE COL -->
            <!--
               v-if="
                //reportLeads.showType === 0 &&
                //currentItems.length > 0 &&
                solicitacoes.length > 0
              "
             -->
            <v-col
              v-if="
                reportLeads.showType === 0
                //currentItems.length > 0 &&
              "
            >
              <div
                class="pb-4 pl-1 d-flex justify-space-start flex-wrap"
                v-if="
                  reportLeads.statusDataSolic === 0 && !changedResultsByFilters
                "
              >
                <div class="pr-1 d-flex" style="width: 100%">
                  parâmetros buscados:
                </div>
                <v-chip class="mx-1 mb-1" color="grey lighten-2">
                  <v-layout column class="pb-1">
                    <div style="font-size: 9px; height: 12px">
                      <b>período</b>
                    </div>
                    <div>
                      {{ formatDate(reportLeads.searchSolicitacao.startDate) }}
                      -
                      {{ formatDate(reportLeads.searchSolicitacao.endDate) }}
                    </div>
                  </v-layout>
                </v-chip>
                <v-chip
                  class="mx-1 mb-1"
                  color="orange lighten-4"
                  v-if="reportLeads.searchSolicitacaoWords"
                >
                  <v-layout column class="pb-1">
                    <div style="font-size: 9px; height: 12px">
                      <b>busca por texto</b>
                    </div>
                    <div>
                      {{ reportLeads.searchSolicitacaoWords }}
                    </div>
                  </v-layout>
                </v-chip>
                <v-chip
                  class="mx-1 mb-1"
                  color="green lighten-4"
                  v-for="(item, i) in Object.keys(reportLeads.searchFormFilter)"
                  :key="`${item}_${i}`"
                  v-show="reportLeads.searchFormFilter[item]"
                >
                  <v-layout column class="pb-1">
                    <div style="font-size: 9px; height: 12px">
                      <b>{{ item }}</b>
                    </div>
                    <div>
                      {{ reportLeads.searchFormFilter[item] }}
                    </div>
                  </v-layout>
                </v-chip>
                <v-chip
                  class="mx-1 mb-1"
                  color="indigo lighten-4"
                  v-for="(item, i) in Object.keys(reportLeads.filterSelected)"
                  :key="`${item}_${i}`"
                  v-show="reportLeads.filterSelected[item]"
                >
                  <v-layout column class="pb-1">
                    <div style="font-size: 9px; height: 12px">
                      <b>{{ item | noUnderline }}</b>
                    </div>
                    <div>
                      {{ reportLeads.filterSelected[item] }}
                    </div>
                  </v-layout>
                </v-chip>
              </div>
              <div
                class="pb-4 pl-1 d-flex justify-space-between"
                v-if="reportLeads.statusDataSolic === 0"
              >
                <span class="grey--text text--darken-3"
                  ><b>{{ reportLeads.searchSolicitacao.totalRequests }} </b>
                  {{
                    reportLeads.searchSolicitacao.totalRequests === 1
                      ? "resultado obtido"
                      : "resultados obtidos"
                  }}
                  na perspectiva de
                  <b
                    >{{
                      getDiffDaysBetweenDates(
                        reportLeads.searchSolicitacao.startDate,
                        reportLeads.searchSolicitacao.endDate
                      )
                    }}
                    dias
                  </b>
                </span>
                <span class="grey--text text--darken-3">
                  pág. <b>{{ reportLeads.searchPage }}</b> /
                  {{ reportLeads.searchTotalPages }}
                </span>
              </div>
              <!-- DATA VIEW TABLE -->
              <data-view-table
                v-model="currentItems"
                :headers="headers"
                :get-logo="getLogo"
                :on-scroll="onScrollDataView"
                :solicitacoes="solicitacoes"
                :onSearch="onSearch"
                :onSearchFilter="onApplyFilter"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import mixins from "@/mixins";
import SinglePaginator from "@/components/paginators/single-paginator";
import ExportReportButton from "@/components/buttons/export-report-button";
import SimpleToolbarButton from "@/components/buttons/simple-toolbar-button";
import SelectColumnsToolbarButton from "@/components/dashboard/select-columns-toolbar-button";
import WidgetTotal from "@/components/widgets/widget-total";
import ToolbarSection from "@/components/containers/toolbar-section";
import DashboardToolbar from "@/components/toolbars/dashboard-toolbar";
import FilterPanel from "@/components/dashboard/filter-panel";
import TextSearchPanel from "@/components/dashboard/text-search-panel";
import DataViewCard from "@/components/dashboard/data-view-card";
import DataViewTable from "@/components/dashboard/data-view-table";

const getDaysAgo = (days) =>
  new Date(new Date().setUTCDate(new Date().getUTCDate() - days))
    .toISOString()
    .split("T")[0];

const todayDate = new Date().toISOString().split("T")[0];

const headers = [
  { id:-1, text: "Selecione", value: "solics", width: "115px" },
  { id: 0, text: "ID", value: "solicId", width: "5px" },
  { id: 1, text: "Data/Hora", value: "data_hora_registro", width: "180px" },
  { id: 2, text: "Status Op.", value: "status_op", width: "160px" },
  { id: 3, text: "Status", value: "status", width: "160px" },
  { id: 4, text: "Motivo", value: "motivo", width: "160px" },
  { id: 5, text: "Match Parceiro", value: "match_parceiro", width: "180px" },
  {
    id: 6,
    text: "Link Whatsapp",
    value: "clicou_link_whatsapp",
    width: "140px",
  },
  { id: 7, text: "Tipo", value: "tipo_emprestimo", width: "150px" },
  { id: 8, text: "Af.", value: "nome_afiliado", width: "50px" },
  {
    id: 9,
    text: "Valor Empréstimo",
    value: "valor_emprestimo",
    width: "180px",
  },
  { id: 10, text: "Valor Parcela", value: "valor_parcela", width: "150px" },
  {
    id: 11,
    text: "Perguntas",
    value: "total_perguntas_respondidas",
    width: "120px",
  },
  { id: 12, text: "Idade", value: "idade", width: "100px" },
  { id: 13, text: "Data Nasc.", value: "data_nasc", width: "100px" },
  { id: 14, text: "Restrição", value: "restricao_spc", width: "140px" },
  { id: 15, text: "Ocupação", value: "ocupacao", width: "140px" },
  { id: 16, text: "Renda", value: "renda", width: "140px" },
  { id: 17, text: "Faturamento", value: "faturamento", width: "160px" },
  { id: 18, text: "Cidade", value: "cidade", width: "180px" },
  { id: 19, text: "UF", value: "uf", width: "80px" },
  { id: 20, text: "CEP", value: "cep", width: "140px" },
  { id: 21, text: "CPF", value: "cpf", width: "140px" },
  { id: 22, text: "CNPJ", value: "cnpj", width: "160px" },
  { id: 23, text: "Nome", value: "nome", width: "320px" },
  { id: 24, text: "E-mail", value: "email", width: "320px" },
  { id: 25, text: "Celular", value: "celular", width: "140px" },
  {
    id: 26,
    text: "Última Mensagem Whatsapp",
    value: "whatsapp_ultima_mensagem",
    width: "200px",
  },
];

export default {
  mixins: [mixins],
  components: {
    DataViewTable,
    DataViewCard,
    TextSearchPanel,
    FilterPanel,
    DashboardToolbar,
    ToolbarSection,
    WidgetTotal,
    SelectColumnsToolbarButton,
    SimpleToolbarButton,
    ExportReportButton,
    SinglePaginator,
  },
  data: () => ({
    title: "Dashboard",
    currentItems: [],
    textSearch: "",
    headers: [],
    dayFilterClicked: 0,
    lastUpdate: new Date(),
    timer: undefined,
    showFab: false,
    lastScroll: 0,
    solicitacoes: [],
    isSearchedIn30Days: false,
    showSelectedFilters: false,
    resultsByFilters: null,
    changedResultsByFilters: false,
    selectedLastFilterFocus: null,
  }),
  watch: {
    resultsByFilters(next) {
      if (next === false) this.changedResultsByFilters = true;
      else this.changedResultsByFilters = false;
    },
    "$store.state.emitEventHomeOnSearch"(isEmit) {
      if (isEmit) {
        this.onSearch(this.reportLeads.searchFormFilter);
        this.$store.commit("setEmitEventHomeOnSearch", false);
      }
    },
    "$store.state.showModalExportSolics"(value) {
      if (value) {
        document
          .querySelectorAll("table tr th:nth-child(1)")
          .forEach((item) => item.style.display = "table-cell");
        document
          .querySelectorAll("table tr td:nth-child(1)")
          .forEach((item) => item.style.display = "table-cell");
      } else {
        document
          .querySelectorAll("table tr th:nth-child(1)")
          .forEach((item) => (item.style.display = "none"));
        document
          .querySelectorAll("table tr td:nth-child(1)")
          .forEach((item) => (item.style.display = "none"));
      }
    },
  },
  computed: {
    keymap() {
      return {
        "ctrl+m": () => {
          const pageContainer = document.querySelector(".page-container");
          if (pageContainer) {
            pageContainer.scrollTop = !this.reportLeads.showFilter ? 0 : 210;
          }
          this.onToggleFilter();
        },
        "ctrl+b": () => {
          const input = document.querySelector(".text-search-panel input");
          input && input.focus();
        },
        "ctrl+f": () => {
          this.showSelectedFilters = true;
          setTimeout(() => {
            const input = document.querySelector(
              "." +
                this.$refs[
                  this.selectedLastFilterFocus || "autocompleteRef_0"
                ][0].$el.className.replace(/\s/g, ".") +
                " input"
            );
            input && input.click();
          }, 400);
        },
      };
    },
    reportLeads() {
      return this.$store.state.dashboard.leadReports;
    },
    solicSearch() {
      return this.$store.state.dashboard.leadReports.searchSolicitacao;
    },
    showProgressbar() {
      return this.reportLeads.statusDataSolic === 1 || this.reportLeads.status;
    },
    getColByFilter() {
      return (name) => {
        switch (name) {
          case "tipo_emprestimo":
            return "6";
          case "cidade":
            return "4";
          case "uf":
            return "2";
          case "status":
          case "status_op":
          case "match_parceiro":
            return "4";
          default:
            return "4";
        }
      };
    },
  },
  mounted() {
    this.init();
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      // verifica se os filtros estão ativos
      // então não prossegue com a atualização automatica
      if (
        Object.keys(this.reportLeads.filterSelected).some(
          (e) => this.reportLeads.filterSelected[e]
        )
      )
        return console.info("filtros ativos, atualização automatica cancelada.");
      // oculta a barra de progresso quando estiver atualizando automaticamente
      this.$store.commit("dashboard/leadReports/setHiddenProgress", true);

      if (
        // verifica se os filtros não estão sendo editados
        !this.reportLeads.showFilter ||
        // verifica se o campo de busca não esta sendo editado
        document.activeElement !==
          document.querySelector(".text-search-panel input")
        // verifica se os campos de busca por formulario estão desativados  
        || !this.reportLeads.searchFormFilter  
      ) {
        this.onSearch(this.reportLeads.searchFormFilter);
      }
      else if (!this.reportLeads.searchActive) {
        this.$store.dispatch("dashboard/leadReports/getSolicitacoes", {
          page: this.reportLeads.searchPage ? this.reportLeads.searchPage : "1",
        });
      }
      if (this.headers.length === 0) {
        this.addAllHeadersChecked();
      }
    }, 60 * 10000);
  },
  methods: {
    init() {
      this.$store.commit("dashboard/leadReports/setDayFilterClicked", 0);

      const pageContainer = document.querySelector(".page-container");
      if (pageContainer) pageContainer.onscroll = this.onScrollDataView;

      // dispara a busca para carregar a tabela do dashboard
      this.onSearch();

      if (this.headers.length === 0) {
        this.addAllHeadersChecked();
      }
    },
    async onReportExcel() {
      await this.$store.dispatch("dashboard/leadReports/getExcelReportBasic");
      window.open(
        this.$store.state.baseReportsApiURL + this.reportLeads.reportURL,
        "_blank"
      );
    },
    onToggleCheckHeader(evt) {
      this.headers = [];

      evt.map((e) => this.headers.push(headers.find((f) => f.value === e)));

      this.headers.sort((a, b) => a.id - b.id);

      this.$store.commit(
        "dashboard/leadReports/setHeaderCheckedAltered",
        this.reportLeads.headersChecked.length !== headers.length
      );
    },
    onToggleFilter() {
      this.$store.commit(
        "dashboard/leadReports/setShowFilter",
        !this.reportLeads.showFilter
      );
    },
    onSearch(filters = {}) {
      this.showSelectedFilters = false;
      const customDate = this.reportLeads.searchCustomDate;

      // oculta o painel de filtros e rola a tela
      this.$store.commit("dashboard/leadReports/setShowFilter", false);
      const pageContainer = document.querySelector(".page-container");
      if (pageContainer) {
        this.$nextTick(() => (pageContainer.scrollTop = 415));
      }

      this.$store.commit("dashboard/leadReports/setHiddenProgress", false);
      if (
        !this.reportLeads.searchSolicitacao.startDate ||
        !this.reportLeads.searchSolicitacao.endDate
      ) {
        this.$store.commit("dashboard/leadReports/setSearchActive", false);
        this.$store.commit("dashboard/leadReports/setTotalLeads", 0);
        this.$store.commit("dashboard/leadReports/setTotalSolicitacoes", 0);

        this.addAllHeadersChecked();
      }
      const search = () => {
        const words = wordsFactory(this, filters);

        return this.$store.dispatch(
          "dashboard/leadReports/getSearchSolicitacao",
          {
            page: this.reportLeads.searchPage,
            words,
            filterwords: filterWordsFactory(this.reportLeads.filterSelected),
          }
        );
      };

      const updateTotals = (res) => {
        this.$store.commit(
          "dashboard/leadReports/setSearchSolicitacaoTotal",
          res.totalReq.total
        );
        this.$store.commit(
          "dashboard/leadReports/setSearchTotalPages",
          res.totalReq.total
        );
        this.lastUpdate = new Date();
        // oculta a barra de progresso para não exibir quando estiver atualizando automaticamente
        this.$store.commit("dashboard/leadReports/setHiddenProgress", true);

        if (pageContainer) {
          this.$nextTick(() => (pageContainer.scrollTop = 415));
        }
      };

      if (!customDate) {
        this.setStartDateAndEndDate(getDaysAgo(30), todayDate);
      }

      search()
        .then((res) => {
          this.solicitacoes = res.content.requests || [];

          this.isSearchedIn30Days = this.solicitacoes.length > 0;
          updateTotals(res);

          return Promise.resolve();
        })
        .then(async () => {
          if (!this.isSearchedIn30Days && !customDate) {
            this.setStartDateAndEndDate(getDaysAgo(60), todayDate);

            return new Promise((resolve) =>
              search().then((res) => {
                this.solicitacoes = res.content.requests || [];

                updateTotals(res);

                this.isSearchedIn30Days = false;

                this.changedResultsByFilters = false;

                resolve();
              })
            );
          }
        })
        .then(() => {
          this.getStatistics().then(() => {
            this.$store.dispatch("dashboard/leadReports/getFiltrosAgrupados", {
              filtros: Object.keys(this.reportLeads.filterSelected),
            });
          });

          this.hideSelectedColumn();
        })
        .catch((err) => {
          console.error(err);
        });
      if (this.headers.length === 0) {
        this.addAllHeadersChecked();
      }

      this.getStatistics();

      this.toHome();

      // limpa o campo de busca de texto
      // se ele não estiver sendo usado para a busca
      const input = document.querySelector(".text-search-panel input");
      if (input !== document.activeElement) input.value = "";

      // retirar o focus do elemento ativo
      document.activeElement.blur();
    },
    // onClickFilterText() {
    //   if (this.headers.length === 0) {
    //     this.addAllHeadersChecked();
    //   }
    // },
    onClickSolicitacoes() {
      this.$store.commit("dashboard/leadReports/setHiddenProgress", false); // exibir a barra de progresso
      this.$store.commit("dashboard/leadReports/setSearchSolicitacao", {
        startDate: undefined,
        endDate: undefined,
      });
      this.$store.commit("dashboard/leadReports/setDayFilterClicked", 0);
      this.onSearch();
    },
    onClickToday() {
      this.$store.commit("dashboard/leadReports/setHiddenProgress", false); // exibir a barra de progresso
      this.$store.commit("dashboard/leadReports/setSearchSolicitacao", {
        startDate: todayDate,
        endDate: todayDate,
      });
      this.onSearch();
      this.$store.commit("dashboard/leadReports/setDayFilterClicked", 1);
      if (this.$route.query.page) {
        this.$router.push({ name: "Home" });
      }
    },
    onClickYesterday() {
      this.$store.commit("dashboard/leadReports/setHiddenProgress", false); // exibir a barra de progresso
      let yesterdayDate = new Date(
        new Date().setUTCDate(new Date().getUTCDate() - 1)
      )
        .toISOString()
        .split("T")[0];
      this.$store.commit("dashboard/leadReports/setSearchSolicitacao", {
        startDate: yesterdayDate,
        endDate: yesterdayDate,
      });
      this.onSearch();
      this.$store.commit("dashboard/leadReports/setDayFilterClicked", 2);
      if (this.$route.query.page) {
        this.$router.push({ name: "Home" });
      }
    },
    onClickLast5Days() {
      this.$store.commit("dashboard/leadReports/setHiddenProgress", false); // exibir a barra de progresso
      let todayDate = new Date(
        new Date().setUTCDate(new Date().getUTCDate() - 1)
      )
        .toISOString()
        .split("T")[0];
      let last5DaysDate = new Date(
        new Date().setUTCDate(new Date().getUTCDate() - 5)
      )
        .toISOString()
        .split("T")[0];
      this.$store.commit("dashboard/leadReports/setSearchSolicitacao", {
        startDate: last5DaysDate,
        endDate: todayDate,
      });
      this.onSearch();
      this.$store.commit("dashboard/leadReports/setDayFilterClicked", 3);
      if (this.$route.query.page) {
        this.$router.push({ name: "Home" });
      }
    },
    onClickTopFab() {
      if (this.reportLeads.showType === 0) {
        document.querySelector(".v-data-table__wrapper").scrollTo(0, 0);
      } else {
        document.querySelector(".page-container").scrollTo(0, 0);
      }
    },
    onScrollDataView() {
      this.$nextTick(() => {
        let currentScroll = 0;

        // showType 0 é em formato de tabela
        // hiddenProgress = true quer dizer que a tabela foi carregada para não dar erro no scroll
        if (
          this.reportLeads.showType === 0 &&
          this.reportLeads.hiddenProgress === true
        ) {
          currentScroll =
            document.querySelector(".v-data-table__wrapper") &&
            document.querySelector(".v-data-table__wrapper").scrollTop;
        } else {
          currentScroll = document.querySelector(".page-container").scrollTop;
        }

        if (currentScroll === 0) return (this.showFab = false);

        const diff = Math.abs(Math.ceil(currentScroll - this.lastScroll));

        if (currentScroll > 0 && diff >= 400) {
          this.lastScroll = currentScroll;
          this.showFab = true;
          clearTimeout(window.__scrollTimer);
          window.__scrollTimer = setTimeout(() => {
            this.showFab = false;
            clearTimeout(window.__scrollTimer);
          }, 3000);
        }
      });
    },
    onToggleSelectFilters() {
      this.showSelectedFilters = !this.showSelectedFilters;
      if (this.showSelectedFilters) {
        this.$nextTick(() => this.$refs.containerSelectFilters.scrollTo(0, 0));
      }
    },
    onApplyFilter() {
      this.showSelectedFilters = false;
      document.activeElement && document.activeElement.blur();
      const scrollTop = () => {
        const pageContainer = document.querySelector(".page-container");
        if (pageContainer && this.showSelectedFilters) {
          this.$nextTick(() => (pageContainer.scrollTop = 315 + 500));
        }
      };

      this.$store.commit("dashboard/leadReports/setHiddenProgress", false);

      if (!this.resultsByFilters)
        this.$store.commit("dashboard/leadReports/setSearchSolicitacaoPage", 1);

      this.$store
        .dispatch("dashboard/leadReports/getSolicitacoesFiltradas", {
          words: wordsFactory(this, this.reportLeads.searchFormFilter),
          filterwords: filterWordsFactory(this.reportLeads.filterSelected),
          page: !this.resultsByFilters ? 1 : this.reportLeads.searchPage,
        })
        .then((res) => {
          this.solicitacoes = res.content.requests || [];
          this.$store.commit("dashboard/leadReports/setHiddenProgress", true);
          this.$store.commit(
            "dashboard/leadReports/setSearchTotalPages",
            res.totalReq.total
          );
          scrollTop();
        })
        .catch((err) => {
          this.resultsByFilters = false;
          console.error(err);
        });

      scrollTop();

      this.resultsByFilters = true;
    },
    onSelectFilter() {
      this.resultsByFilters = false;
    },
    onSelectedFilerFocus(ref) {
      this.selectedLastFilterFocus = ref;
    },
    onClearFilters() {
      this.$store.commit("dashboard/leadReports/setSearchSolicitacaoPage", 1);
      this.$store.commit("dashboard/leadReports/setSearchFormFilter", {});
      this.$store.commit("dashboard/leadReports/setSearchSolicitacaoWords", "");
      this.$store.commit("dashboard/leadReports/setFilterSelected", {
        tipo_emprestimo: null,
        cidade: null,
        uf: null,
        status: null,
        status_op: null,
        match_parceiro: null,
        ocupacao: null,
        restricao_spc: null,
        nome_afiliado: null,
      });
      this.onSearch();
    },
    getStatistics() {
      const promises = [
        this.$store.dispatch("dashboard/leadReports/getTotals", {
          type: "solic",
        }),
        this.$store.dispatch("dashboard/leadReports/getTotals", {
          type: "leads",
        }),
        this.$store.dispatch("dashboard/leadReports/getTotals", {
          type: "solicToday",
        }),
        this.$store.dispatch("dashboard/leadReports/getTotals", {
          type: "solicYesterday",
        }),
        this.$store.dispatch("dashboard/leadReports/getTotals", {
          type: "solicLast5Days",
        }),
      ];

      return Promise.allSettled(promises);
    },
    getHeaders() {
      return headers;
    },
    getLogo(value) {
      switch (value && value.toLowerCase()) {
        case "supersim":
          return "/parceiros/logo-supersim.png";
        case "lendico":
          return "/parceiros/logo-lendico.png";
        case "bv":
          return "/parceiros/logo-bvfinanceira.png";
        case "bxblue":
          return "/parceiros/logo-bxblue.png";
        case "galleria":
          return "/parceiros/logo-galleria.png";
        case "provu":
          return "https://s3.sa-east-1.amazonaws.com/selfcred.partners.logo/provu.png";
        case "baduk":
          return "https://s3.sa-east-1.amazonaws.com/selfcred.partners.logo/baduk.png";
        case "biz capital":
          return "https://s3.sa-east-1.amazonaws.com/selfcred.partners.logo/bizcapital.png";
        case "bv financeira":
          return "https://s3.sa-east-1.amazonaws.com/selfcred.partners.logo/banco_bv.png";
        case "creditas":
          return "https://s3.sa-east-1.amazonaws.com/selfcred.partners.logo/creditas.png";
        default:
          return !value || (value && value.toLowerCase() === "nenhum")
            ? null
            : "sem-logo";
      }
    },
    removeAllHeadersChecked() {
      this.$store.commit("dashboard/leadReports/setHeadersChecked", []);
      this.headers = [];
      this.$store.commit("dashboard/leadReports/setHeaderCheckedAltered", true);
    },
    addAllHeadersChecked() {
      this.headers = headers;
      this.$store.commit(
        "dashboard/leadReports/setHeadersChecked",
        headers.map((e) => e.value)
      );
      this.$store.commit(
        "dashboard/leadReports/setHeaderCheckedAltered",
        false
      );
    },
    toHome() {
      if (this.$route.name !== "Home") this.$router.push({ name: "Home" });
    },
    setPage: function (pageNumber) {
      this.currentPage = pageNumber;
    },
    setStartDateAndEndDate(startDate, endDate) {
      this.$store.commit("dashboard/leadReports/setSearchSolicitacao", {
        startDate,
        endDate,
      });
      this.$store.commit("dashboard/leadReports/setFilterReport", {
        startDate,
        endDate,
      });
    },
    removeFilterItemSelected(i) {
      this.reportLeads.filterSelected[i] = null;
      this.resultsByFilters = false;
    },
    hideSelectedColumn() {
      // Oculta o header e a primeira coluna
      document
        .querySelectorAll("table tr th:nth-child(1)")
        .forEach((item) => (item.style.display = "none"));
      document
        .querySelectorAll("table tr td:nth-child(1)")
        .forEach((item) => (item.style.display = "none"));
    },
  },
};

function wordsFactory(self, filters) {
  let words = "";

  if (!words && self.reportLeads.searchSolicitacaoWords) {
    words = self.reportLeads.searchSolicitacaoWords;
  }

  const keys = Object.keys({
    nome: filters.nome,
    email: filters.email,
    celular: filters.celular,
    cpf: filters.cpf,
    cnpj: filters.cnpf,
    cidade: filters.cidade,
    uf: filters.uf,
  });

  keys.forEach((field, i) => {
    // adiciona o caracter '»'
    // criando uma lógica de separação de
    // palavras, pois assim envia-se um só
    // parametro 'words' e no backend,
    // esse parametro é decodificado,
    // quebrando as palavras que foram sepradas
    // entre esse caracter.
    words +=
      i <= Object.keys(filters).length - 1 ? (filters[field] ?? "") + "»" : "";
  });

  return words;
}

function filterWordsFactory(filters) {
  let words = "";

  const keys = Object.keys({
    cidade: filters.cidade,
    uf: filters.uf,
    tipo_emprestimo: filters.tipo_emprestimo,
    restricao_spc: filters.restricao_spc,
    ocupacao: filters.ocupacao,
    status_op: filters.status_op,
    status: filters.status,
    match_parceiro: filters.match_parceiro,
    nome_afiliado: filters.nome_afiliado,
  });

  keys.forEach((field, i) => {
    // adiciona o caracter '»'
    // criando uma lógica de separação de
    // palavras, pois assim envia-se um só
    // parametro 'words' e no backend,
    // esse parametro é decodificado,
    // quebrando as palavras que foram sepradas
    // entre esse caracter.
    words +=
      i <= Object.keys(filters).length - 1 ? (filters[field] ?? "") + "»" : "";
  });

  return words;
}
</script>

<style lang="scss">
</style>
