<template>
  <v-card class="text-search-panel">
    <v-card-text>
      <!-- <v-text-field
          @input="onInputLazySearch"
          :hide-details="!textSearch || currentItems.length === 0"
          prepend-inner-icon="mdi-magnify"
          label="buscar por texto" dense outlined
          @click="onClickFilterText"
      /> -->
      <v-text-field
        v-model="solicSearch.words"
        prepend-inner-icon="mdi-magnify"
        label="buscar por texto"
        dense
        outlined
        @keyup.enter="_onSearch"
      />
      <!-- <div v-if="textSearch && currentItems.length > 0" class="subtitle-1">
        {{
          currentItems.length > 1
            ? currentItems.length + " resultados encontrados"
            : currentItems.length === 1
            ? currentItems.length + " resultado encontrado"
            : ""
        }}
      </div> -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["currentItems", "onClickFilterText", "onSearch"],

  data: () => ({
    timeout: undefined,
  }),

  computed: {
    solicSearch() {
      return this.$store.state.dashboard.leadReports.searchSolicitacao;
    },
  },

  watch: {
    searchWords() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this._onSearch(), 1000);
    },
  },
  methods: {
    _onSearch() {
      this.$store.commit(
        "dashboard/leadReports/setSearchSolicitacaoWords",
        this.solicSearch.words
      );
      this.$store.commit("dashboard/leadReports/setSearchSolicitacaoPage", 1);
      this.onSearch(this.$store.state.dashboard.leadReports.searchFormFilter);
    },
  },
};
</script>

<style scoped>
</style>
