<template>
  <div class="select-columns-toolbar-button">
    <v-layout justify-start>
      <v-dialog
          v-model="show"
          width="500"
          scrollable
          max-width="400"
      >
        <template #activator="{ on, attrs }">
          <v-btn
              :color="show ? 'blue' : ''"
              v-bind="attrs"
              v-on="on"
              icon
          >
            <v-icon>mdi-order-bool-ascending-variant</v-icon>
            <v-badge v-if="reportLeads.headerCheckedAltered" dot offset-x="7" offset-y="-5" color="red lighten-1"></v-badge>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            Colunas
            <v-spacer></v-spacer>
            <v-btn icon @click="show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-layout justify-space-between>
                  <v-btn x-small @click="onAddAllHeadersChecked">habilitar todos</v-btn>
                  <v-btn x-small @click="onRemoveAllHeadersChecked">remover todos</v-btn>
                </v-layout>
              </v-list-item>
              <v-list-item v-for="(item, i) in getHeaders" :key="i">
                <v-checkbox
                    v-model="reportLeads.headersChecked"
                    :label="item.text"
                    :value="item.value"
                    @change="onChange($event)"
                />
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: [
      'onChange',
      'onAddAllHeadersChecked',
      'onRemoveAllHeadersChecked',
      'headers'
  ],
  data: () => ({
    show: false
  }),
  computed: {
    reportLeads() {
      return this.$store.state.dashboard.leadReports;
    },
    getHeaders() {
      return this.headers.filter(e => e.id > 0)
    }
  }
}
</script>

<style scoped>

</style>
