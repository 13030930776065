<template>
  <div class="simple-toolbar-button">
    <v-layout justify-start align-center>
      <v-btn :color="color" icon @click="onClick" :title="title">
        <v-icon>{{icon}}</v-icon>
        <v-badge v-if="conditionShowBadge" dot offset-x="7" offset-y="-5" color="red lighten-1"></v-badge>
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ['color','onClick', 'icon', 'conditionShowBadge', 'title']
}
</script>

<style scoped>

</style>
