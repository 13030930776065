<template>
  <div class="export-report-button">
    <v-snackbar :value="status === -1" color="error" style="position: fixed" top>
      <v-icon>mdi-alert-circle</v-icon>
      Erro ao tentar gerar o relatório
    </v-snackbar>
    <v-layout>
      <v-btn icon @click="onClick">
        <v-icon :class="iconColor" large>{{icon}}</v-icon>
        <v-progress-circular
            v-if="status === 1"
            color="blue"
            indeterminate
            style="position: absolute; right: -15px; bottom: 5px"
            width="2"
            size="15"
        />
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ['icon', 'onClick', 'iconColor', 'status']
}
</script>

<style scoped>

</style>
