export function saveAsXHTML(filename, data, width, height, getBlob) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  const img = document.createElement('img');
  const styles = ` 
  style="
    width: ${width}px;
    height: ${height}px;
    margin: 60px auto 0;
    position: relative;
    top: 0;"`
  const svg = `
  <svg xmlns="http://www.w3.org/2000/svg" ${styles}>
      <foreignObject 
          style="
              width: 100%;
              height: 100%;
              transform:scale(1);
          "
      > ${data}
      </foreignObject>
  </svg>
`
  if (getBlob) return getBlob(svg)

  img.onload = () => {
    ctx.drawImage(img, 0, 0);
    const a = document.createElement('a');
    a.href = img.src
    a.download = filename + '.xhtml';
    a.click();
  }

  img.onerror = (err) => console.error(err);

  img.src = 'data:image/svg+xml;charset=uft8,' + encodeURIComponent(svg);

}

export function saveAsPNG(filename, data, width, height, getBlob) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height
  const img = document.createElement('img');
  const svg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
      <foreignObject 
          style="
              width: 100%;
              height: 100%;
              transform:scale(1);
          "
      > ${data}
      </foreignObject>
  </svg>
`

  img.onload = () => {
    ctx.drawImage(img, 0, 0);
    if (getBlob) return canvas.toBlob(getBlob)
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/png');
    a.download = filename + '.png';
    a.click();
  }

  img.onerror = (err) => console.error(err);

  img.src = 'data:image/svg+xml;utf8,' + encodeURIComponent(svg);

}

export function saveAsCSV(filename, data, getBlob = null) {
  let csv = '';
  Object.keys(data).forEach(e => {
    csv += `${e.replace(/_/, ' ').toUpperCase()}; ${encodeURIComponent(data[e] || '')}\n`
  })

  if (getBlob) return getBlob(csv)

  var a = document.createElement('a');
  a.href = 'data:text/csv;charset=utf-8,' + csv;
  a.download = filename + '.csv';
  a.click();
}