<template>
  <v-dialog scrollable :value="show" class="data-view-card" persistent>
    <v-card height="100%">
      <v-card-title>
        Detalhes
        <v-spacer></v-spacer>
        <!-- TOP BUTTONS -->
        <div class="mr-6">
          <!-- BUTTON FILES -->
          <v-dialog max-width="480" v-model="showModalViewDocuments" scrollable>
            <template #activator="{ on, attrs }">
              <v-btn
                text
                color="primary"
                v-on="on"
                v-bind="attrs"
                small
                @click="() => {}"
              >
                <v-icon small>mdi-file-multiple</v-icon>
                <span>Documentos</span>
              </v-btn>
            </template>
            <!-- VIEW DOCUMENTS -->
            <data-view-documents
              v-model="showModalViewDocuments"
              :number="item.solicId"
              :item="item"
            />
          </v-dialog>
          <!-- BUTTON EXPORTAR -->
          <v-dialog max-width="480" v-model="showModalExportDetails" scrollable>
            <template #activator="{ on, attrs }">
              <v-btn
                text
                color="primary"
                v-on="on"
                v-bind="attrs"
                small
                @click="() => {}"
              >
                <v-icon small>mdi-file-export</v-icon>
                <span>Exportar</span>
              </v-btn>
            </template>
            <!-- FORM EXPORT DETAILS -->
            <form-export-details
              v-model="showModalExportDetails"
              :number="item.solicId"
              :item="item"
            />
          </v-dialog>
          <!-- BUTTON INCLUIR STATUS MANUAL -->
          <v-dialog
            max-width="480"
            v-model="showModalFeedbackStatus"
            scrollable
          >
            <template #activator="{ on, attrs }">
              <v-btn
                text
                color="primary"
                v-on="on"
                v-bind="attrs"
                small
                @click="() => {}"
              >
                <v-icon small>mdi-file-document-edit</v-icon>
                <span>Incluir Status</span>
              </v-btn>
            </template>
            <!-- FORM FEEDBACK STATUS -->
            <form-feedback-status
              v-model="showModalFeedbackStatus"
              :number="item.solicId"
              :item="item"
            />
          </v-dialog>
        </div>
        <v-btn icon @click="() => $emit('change', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-layout justify-start align-center wrap>
          <!-- NUM -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="grey--text text--darken-3 font-weight-bold">
              #{{ item.solicId }}
            </span>
            <v-layout column>
              <!-- DATA/HORA -->
              <span class="caption">Data/Hora</span>
              <span class="grey--text text--darken-4">{{
                formatDateRegister(item.data_hora_registro)
              }}</span>
            </v-layout>
          </v-layout>
          <!-- STATUS OP -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Status Op.</span>
            <v-chip class="caption lighten-3 text--darken-4 text-uppercase">
              {{ item.status_op | noUnderline }}
            </v-chip>
          </v-layout>
          <!-- STATUS -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Status</span>
            <v-chip
              class="caption lighten-3 text--darken-4"
              :class="
                item.status === 'SEM DIRECIONAMENTO'
                  ? 'amber amber--text'
                  : 'green green--text'
              "
            >
              {{ item.status | noUnderline }}
            </v-chip>
          </v-layout>
          <!-- MOTIVO -->
          <v-layout v-if="item.motivo" column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Motivo</span>
            <v-chip
              v-if="item.motivo && item.motivo === 'sem link'"
              class="caption lighten-4 text--darken-4"
            >
              {{ item.motivo }}
            </v-chip>
            <v-chip
              v-else-if="item.motivo && item.motivo !== 'sem match'"
              class="caption lighten-4 text--darken-4 red--text red"
            >
              {{ item.motivo }}
            </v-chip>
            <v-chip v-else class="caption lighten-4 text--darken-4">
              {{ item.motivo }}
            </v-chip>
          </v-layout>
          <!-- MATCH PARCEIRO -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">{{
              item.status === "SEM DIRECIONAMENTO" ? "Match" : "Parceiro"
            }}</span>
            <v-flex style="height: 30px; min-width: 150px">
              <v-img
                v-if="
                  item.match_parceiro !== 'nenhum' &&
                  getLogo(item.match_parceiro) !== 'sem-logo'
                "
                height="46"
                max-width="120"
                contain
                :src="getLogo(item.match_parceiro)"
              />
              <span
                v-else-if="item.match_parceiro !== 'nenhum'"
                class="pl-5 grey--text text-uppercase text--darken-3"
                >{{ item.match_parceiro }} - sem logotipo</span
              >
              <span v-if="item.match_parceiro === 'nenhum'">
                <span class="caption pr-3">nenhum</span>
                <v-icon>mdi-circle-off-outline</v-icon>
              </span>
            </v-flex>
          </v-layout>
          <!-- LINK WHATSAPP -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Link Whatsapp</span>
            <v-flex>
              <span class="grey--text text--darken-4">{{
                item.clicou_link_whatsapp || "não clicou"
              }}</span>
              <v-icon v-if="item.clicou_link_whatsapp === 'CLICK'"
                >mdi-cursor-default-click</v-icon
              >
            </v-flex>
          </v-layout>
          <!-- DIVIDER #1 -->
          <v-layout style="width: 100%" class="pb-3">
            <v-divider></v-divider>
          </v-layout>
          <!-- COMENTARIO STATUS OP -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <div class="caption d-flex justify-space-between">
              <div>Comentário Status Op.</div>
              <div class="pr-0">
                <v-btn
                  v-if="reportLeads.ultimosStatusOp ? reportLeads.ultimosStatusOp.length > 1 : false"
                  text
                  x-small
                  class="text-lowercase"
                  color="primary"
                  @click="showAllComments = !showAllComments"
                >
                  {{ showAllComments ? "exibir menos" : "exibir mais" }}
                </v-btn>
              </div>
            </div>
            <v-flex>
              <v-list style="border-radius: 8px">
                <v-list-item
                  v-for="(item, i) in getComments"
                  :key="i"
                  class="px-2 py-2 grey lighten-4"
                >
                  <v-layout column>
                    <v-layout>
                      <v-list-item-avatar
                        style="border: 1px solid #ddd"
                        size="46"
                      >
                        <img src="/selfcred-logo.png" />
                      </v-list-item-avatar>
                      <v-list-item-content class="pr-6 ml-0 py-1">
                        <v-card class="card-comentario-op mx-4" width="340">
                          <v-card-text class="mb-0 pb-2">
                            <v-list-item-title
                              class="d-flex justify-space-between subtitle-1"
                            >
                              <span>
                                <b>{{ item.status }}</b>
                              </span>
                              <span
                                style="font-size: 12px"
                                class="grey--text text--darken-2"
                              >
                                <!-- -->
                              </span>
                            </v-list-item-title>
                            <v-list-item-subtitle
                              class="pt-2 pb-3"
                              style="white-space: unset"
                            >
                              <i v-if="!item.comentario">
                                <small>sem comentario</small>
                              </i>
                              <span v-else> {{ item.comentario }}</span>
                            </v-list-item-subtitle>
                            <v-list-item-action-text
                              class="d-flex justify-space-between"
                            >
                              <div v-if="item.tipo_manual">Mesa</div>
                              <div v-else>Automação</div>
                              <div>
                                {{ new Date(item.data_hora).toLocaleString() }}
                              </div>
                            </v-list-item-action-text>
                          </v-card-text>
                        </v-card>
                      </v-list-item-content>
                    </v-layout>
                    <v-divider
                      v-if="showAllComments && i < reportLeads.ultimosStatusOp.length - 1"
                      vertical
                      class="py-6 pl-6"
                    ></v-divider>
                  </v-layout>
                </v-list-item>
              </v-list>
            </v-flex>
          </v-layout>
          <!-- DIVIDER #2 -->
          <v-layout style="width: 100%" class="pb-3">
            <v-divider></v-divider>
          </v-layout>
          <!-- ID SOLICITACAO -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">ID Solicitação</span>
            <span class="grey--text text--darken-4">{{ item.solicId }}</span>
          </v-layout>
          <!-- TIPO EMPRESTIMO -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Tipo</span>
            <span class="grey--text text--darken-4">{{
              item.tipo_emprestimo
            }}</span>
          </v-layout>
          <!-- VALOR EMPRESTIMO -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Empréstimo</span>
            <span class="grey--text text--darken-4">{{
              item.valor_emprestimo
                | currency("R$", 2, {
                  spaceBetweenAmountAndSymbol: true,
                  thousandsSeparator: ".",
                  decimalSeparator: ",",
                })
            }}</span>
          </v-layout>
          <!-- VALOR PARCELA -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Parcela</span>
            <span class="grey--text text--darken-4">{{
              item.valor_parcela
                | currency("R$", 2, {
                  spaceBetweenAmountAndSymbol: true,
                  thousandsSeparator: ".",
                  decimalSeparator: ",",
                })
            }}</span>
          </v-layout>
          <!-- DIVIDER #3 -->
          <v-layout style="width: 100%" class="pb-3">
            <v-divider></v-divider>
          </v-layout>
          <!-- QTD PERGUNTAS -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Qtd Perguntas</span>
            <span class="grey--text text--darken-4">{{
              item.total_perguntas_respondidas
            }}</span>
          </v-layout>
          <!-- DATA NASC -->
          <v-layout
            v-if="
              item.data_nasc && reportLeads.headersChecked.includes('data_nasc')
            "
            column
            class="mr-5 mb-5 flex-grow-0"
          >
            <span class="caption">Data Nasc.</span>
            <span class="grey--text text--darken-4">{{ item.data_nasc }}</span>
          </v-layout>
          <!-- IDADE -->
          <v-layout v-if="item.idade" column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Idade</span>
            <span class="grey--text text--darken-4">{{ item.idade }}</span>
          </v-layout>
          <!-- RESTRICAO -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Restrição</span>
            <span class="grey--text text--darken-4">{{
              item.restricao_spc || "não respondido"
            }}</span>
          </v-layout>
          <!-- OCUPACAO -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Ocupação</span>
            <span class="grey--text text--darken-4">{{
              item.ocupacao || "não respondido"
            }}</span>
          </v-layout>
          <!-- RENDA -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Renda</span>
            <span class="grey--text text--darken-4">{{
              (!item.renda ? 0 : item.renda)
                | currency("R$", 2, {
                  spaceBetweenAmountAndSymbol: true,
                  thousandsSeparator: ".",
                  decimalSeparator: ",",
                })
            }}</span>
          </v-layout>
          <!-- FATURAMENTO -->
          <v-layout
            v-if="
              item.faturamento_mensal &&
              reportLeads.headersChecked.includes('faturamento')
            "
            column
            class="mr-5 mb-5 flex-grow-0"
          >
            <span class="caption">Faturamento</span>
            <span class="grey--text text--darken-4">{{
              (!item.faturamento_mensal ? 0 : item.faturamento_mensal)
                | currency("R$", 2, {
                  spaceBetweenAmountAndSymbol: true,
                  thousandsSeparator: ".",
                  decimalSeparator: ",",
                })
            }}</span>
          </v-layout>
          <v-layout
            v-else-if="
              item.faturamento &&
              reportLeads.headersChecked.includes('faturamento')
            "
            column
            class="mr-5 mb-5 flex-grow-0"
          >
            <span class="caption">Faturamento</span>
            <span class="grey--text text--darken-4">{{
              (!item.faturamento ? 0 : item.faturamento)
                | currency("R$", 2, {
                  spaceBetweenAmountAndSymbol: true,
                  thousandsSeparator: ".",
                  decimalSeparator: ",",
                })
            }}</span>
          </v-layout>
          <!-- DIVIDER #4 -->
          <v-layout style="width: 100%" class="pb-3">
            <v-divider></v-divider>
          </v-layout>
          <!-- NOME -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Nome</span>
            <span class="grey--text text--darken-4">{{
              item.nome | capitalize
            }}</span>
          </v-layout>
          <!-- EMAIL -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">E-mail</span>
            <span class="grey--text text--darken-4">{{ item.email }}</span>
          </v-layout>
          <!-- CELULAR -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Celular</span>
            <span class="grey--text text--darken-4">{{ item.celular }}</span>
          </v-layout>
          <!-- CPF -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">CPF</span>
            <span class="grey--text text--darken-4">{{ item.cpf }}</span>
          </v-layout>
          <!-- CNPJ -->
          <v-layout v-if="item.cnpj" column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">CNPJ</span>
            <span class="grey--text text--darken-4">{{ item.cnpj }}</span>
          </v-layout>
          <!-- CIDADE/UF -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <v-layout>
              <span class="caption">Cidade</span>
              <span v-if="item.cidade && item.uf" class="caption">/</span>
              <span v-if="item.uf" class="caption">UF</span>
            </v-layout>
            <v-layout>
              <span v-if="item.cidade" class="grey--text text--darken-4">{{
                item.cidade
              }}</span>
              <span
                v-if="item.cidade && item.uf"
                class="grey--text text--darken-4"
                >-</span
              >
              <span v-if="item.uf" class="grey--text text--darken-4">{{
                item.uf
              }}</span>
            </v-layout>
          </v-layout>
          <!-- CEP -->
          <v-layout
            v-if="item.cep && reportLeads.headersChecked.includes('cep')"
            column
            class="mr-5 mb-5 flex-grow-0"
          >
            <span class="caption">CEP</span>
            <span class="grey--text text--darken-4">{{ item.cep }}</span>
          </v-layout>
          <!-- DIVIDER #5 -->
          <v-layout style="width: 100%" class="pb-3">
            <v-divider></v-divider>
          </v-layout>
          <!-- ULTIMA MSG WHATSAPP -->
          <v-layout column class="mr-5 mb-5 flex-grow-0">
            <span class="caption">Última mensagem whatsapp</span>
            <span class="grey--text text--darken-4">{{
              item.whatsapp_ultima_mensagem || "sem mensagem"
            }}</span>
          </v-layout>
          <!-- DIVIDER #6 -->
          <v-layout
            style="width: 100%"
            class="pb-3"
            v-if="statusProposta.length > 0"
          >
            <v-divider></v-divider>
          </v-layout>
          <!-- STATUS DA PROPOSTA -->
          <div v-if="statusProposta.length > 0">
            <h4>Status da proposta</h4>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Data</th>
                    <th class="text-left">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in statusProposta" :key="item.date">
                    <td>
                      {{
                        new Date(item.date).toLocaleDateString() +
                        " - " +
                        new Date(item.date).toLocaleTimeString()
                      }}
                    </td>
                    <td>{{ item.status }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "@/mixins";
import FormFeedbackStatus from "@/components/dashboard/form-feedback-status";
import FormExportDetails from "@/components/dashboard/form-export-details";
import DataViewDocuments from "@/components/dashboard/data-view-documents";

export default {
  components: { FormFeedbackStatus, FormExportDetails, DataViewDocuments },
  mixins: [mixins],
  props: ["item", "currentItems", "index", "getLogo", "show"],
  model: {
    prop: "show",
    event: "change",
  },
  computed: {
    reportLeads() {
      return this.$store.state.dashboard.leadReports;
    },
    getComments() {
      return this.showAllComments
        ? this.reportLeads.ultimosStatusOp
        : this.reportLeads.ultimosStatusOp.slice(0, 1);
    },
  },
  data: () => ({
    statusProposta: [],
    showModalFeedbackStatus: false,
    showModalExportDetails: false,
    showModalViewDocuments: false,
    showAllComments: true,
  }),
  watch: {
    show(newValue) {
      if (newValue) {
        this.$store
          .dispatch("dashboard/leadReports/getAllStatusOp", {
            solicId: this.item.solicId,
          });
      }
    },
    item(newValue) {
      this.statusProposta = [];
      if (newValue.match_parceiro.toLowerCase().includes("creditas")) {
        this.$store.dispatch("dashboard/leadReports/getCreditasStatus", {
          solicitacaoId: newValue.solicId,
        });
      }
    },
    "reportLeads.creditasStatus"() {
      if (this.reportLeads.creditasStatus.error) {
        this.statusProposta.push({
          date: this.reportLeads.creditasStatus.error.createdAt,
          status: JSON.parse(this.reportLeads.creditasStatus.error.message)
            .message,
        });
      } else if (
        this.reportLeads.creditasStatus.data &&
        this.reportLeads.creditasStatus.data.feeds
      ) {
        this.statusProposta.push({
          date: this.reportLeads.creditasStatus.data.steps[0].progress
            .startedAt,
          status: `Status geral: ${this.reportLeads.creditasStatus.data.status}`,
        });
        this.reportLeads.creditasStatus.data.feeds.map((f) => {
          const desc = f.description ? " - " + f.description : "";
          this.statusProposta.push({
            date: f.createdAt,
            status: `${f.name}${desc}`,
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
.card-comentario-op {
  &:after,
  &:before {
    content: "";
    position: absolute;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid rgba(0, 0, 0, 0.12);
    top: calc(50% - 10px);
    left: -10px;
  }

  &:after {
    border-right-color: rgb(255, 255, 255);
    top: calc(50% - 10px);
    left: -9px;
  }
}
</style>
