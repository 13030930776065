

export default {
  computed: {
    isFullScreen() {
      return this.$vuetify.breakpoint.height === window.screen.height
    },
    convertFromISODate() {
      return dateISOString => new Date(dateISOString).toISOString().split('T')[0].split('-').reverse().join('/')
        + ' ' +
        new Date(dateISOString).toISOString().split('T')[1].split('.')[0]
    },
    todayDateForFilter() {
      return new Date()
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("-");
    }
  },
  methods: {
    getDiffDaysBetweenDates(startDate, endDate) {
      return Math.floor((new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24))
    },
    formatDateRegister(newDate) {
      // return new Date(newDate).toLocaleString();
      if (newDate) {
        const splitDate = new Date(newDate).toISOString().split("T");
        const date = splitDate[0].split("-").reverse().join("/");
        const time = splitDate[1].split(".")[0];
        return date + " " + time;
      }
      return newDate;
    },
    formatDate(newDate) {
      if (newDate) {
        const splitDate = new Date(newDate).toISOString().split("T");
        const date = splitDate[0].split("-").reverse().join("/");
        return date;
      }
      return newDate;
    },
  }
}
