<template>
  <v-card :ripple="false" class="widget-total pa-0 mr-2" min-height="100%" @click="_onClick" :color="cardColor">
    <v-layout fill-height>
      <v-card-text class="d-flex justify-end align-center pa-2">
        <v-card :color="color" elevation="0" dark>
          <v-card-text>
            <v-icon :color="iconColor">{{icon}}</v-icon>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text class="pa-2">
        <v-card-text class="pa-1 subtitle-1 font-weight-regular">{{title}}</v-card-text>
        <slot name="value">
          <v-card-text style="min-width: 120px; max-width: 140px" class="pa-1 pt-0 headline">
            {{value}}
          </v-card-text>
        </slot>
      </v-card-text>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: ['icon', 'title', 'value','color', 'onClick', 'cardColor','iconColor'],
  beforeMount() {
    this._onClick = this.onClick || (() => {})
  }
}
</script>

<style lang="scss">
.widget-total {
  &.v-card--link:focus:before {
    opacity: 0!important;
  }
}
</style>
