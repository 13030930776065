<template>
  <div class="form-upload-files">
    <VueFileAgent
      v-if="status !== 2"
      ref="vueFileAgent"
      :theme="'list'"
      :multiple="true"
      :deletable="true"
      :meta="true"
      :accept="'application/pdf, image/*, .zip, .pdf, .doc, .docx'"
      :maxSize="'11MB'"
      :maxFiles="20"
      :helpText="'Escolha imagens, documentos ou arquivos zip, limite de até 20 arquivos com até 10 MB cada um.'"
      @select="filesSelected($event)"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
      v-model="fileRecords"
    />
    <div
      v-if="status !== 2"
      class="pt-4 d-flex flex-column align-items-end justify-content-end"
    >
      <slot
        name="send-files"
        v-bind="{
          uploadFiles,
          countFiles: fileRecords.length,
          status,
        }"
      />
    </div>
    <div v-else>
      <slot name="success" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["solicId", "callbackSuccess"],
  data: function () {
    return {
      fileRecords: [],
      fileRecordsForUpload: [],
      status: 0,
    };
  },
  methods: {
    uploadFiles() {
      if (!this.checkAfterUpload()) return;

      this.status = 1;
      this.$store.commit('dashboard/leadReports/setUploadFileStatus', 1);
      removeErrorState();

      const timeId = setTimeout(() => {

        this.$store.dispatch('dashboard/leadReports/uploadFileAffiliateBySolic', {
          id: this.solicId,
          uploadFn: (args) => this.$refs.vueFileAgent.upload(args.uploadUrl, args.uploadHeaders, this.fileRecordsForUpload),
        }).then((data) => {
            this.status = 2;
            this.callbackSuccess && this.callbackSuccess(data);
          })
          .catch((err) => {
            console.info(this.fileRecordsForUpload);
            console.error(err);
            this.status = -1;
          })
          .finally(() => clearTimeout(timeId));
      }, 1000);

      function removeErrorState() {
        document
          .querySelectorAll(".file-error-wrapper")
          .forEach((el) => el.click());
      }
    },
    // deleteUploadedFile(fileRecord) {
    //   this.$refs.vueFileAgent.deleteUpload(
    //     this.uploadUrl,
    //     this.uploadHeaders,
    //     fileRecord
    //   );
    // },
    filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete(fileRecord) {
      this.status = 0;
      const i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        const k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Confirmar remover?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        // this.deleteUploadedFile(fileRecord);
      }
    },
    checkAfterUpload() {
      const filesExceeded = [];
      this.fileRecords.forEach((e) => {
        const [size, simbol] = e.sizeText.split(" ");
        if (size >= 11 && simbol === "MB") {
          filesExceeded.push(e.file.name);
        }
      });

      if (filesExceeded.length > 0)
        alert(
          `Remova os arquivos mariores que 10 MB \n\n ${filesExceeded.join(
            "\n"
          )}`
        );

      return filesExceeded.length === 0;
    },
  },
};
</script>

<style>
.vue-file-agent .file-error-wrapper .file-error-message,
.vue-file-agent .file-preview-wrapper .file-preview .file-progress {
  display: none !important;
}

.form-upload-files {
  padding: 10px;
}
</style>