<template>
  <v-card class="data-view-documents" :ripple="false" :hover="false">
    <!-- TITLE -->
    <v-card-title>
      Documentos Anexos: <b class="pl-1" style="color: #444">#{{ number }}</b>
    </v-card-title>
    <!-- CONTENT -->
    <v-card-text v-show="!showUploadForm">
      <v-layout>
        <v-list class="flex-grow-1">
          <template v-if="files.length > 0">
            <v-list-item
              class="file-item ma-0 pa-0"
              v-for="(item, i) in files"
              :key="i"
            >
              <v-layout column>
                <v-list-item class="ma-0 pa-0">
                  <v-list-item-icon class="mr-4">
                    <v-icon
                      style="font-size: 46px"
                      :class="getTextColor(item.tipo)"
                      >{{ getFileIcon(item.tipo) }}</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-content>
                    <p class="title">{{ item.nome.replace(/_/g, " ") }}</p>
                    <p class="caption">{{ getSize(item.tamanho) }}</p>
                    <p class="caption">
                      enviado em:
                      {{ new Date(item.createdAt).toLocaleString() }}
                    </p>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      icon
                      text
                      class="btn-download"
                      title="click para baixar o arquivo"
                      @click="download(item.id, item.nome, item.tipo)"
                    >
                      <v-icon class="primary--text">mdi-download</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </v-layout>
            </v-list-item>
          </template>
          <v-list-item v-else class="ma-0 pa-0 mt-15">
            <v-layout justify-center align-center column>
              <v-icon style="font-size: 56px" class="grey--text text--lighten-1"
                >mdi-file</v-icon
              >
              <p class="subtitle-1 grey--text text--lighten-0 mt-3">
                Nenhum arquivo anexo
              </p>
            </v-layout>
          </v-list-item>
        </v-list>
      </v-layout>
    </v-card-text>
    <v-layout class="pa-3">
      <v-alert
        :value="requestStatus === -1"
        type="error"
        text
        dense
        transition="scroll-y-transition"
      >
        erro ao obter os arquivos, feche a janela para tentar carregar novamente
      </v-alert>
      <v-alert
        :value="downloadStatus === -1"
        type="error"
        text
        dense
        transition="scroll-y-transition"
      >
        erro ao efetuar o download, tente novamente
      </v-alert>
    </v-layout>
    <!-- UPLOAD FORM -->
    <form-upload-files
      v-if="showUploadForm"
      :solicId="item.solicId"
      :callback-success="afterUploadFiles"
    >
      <template #send-files="{ uploadFiles }">
        <button ref="btnUploadFilesRef" @click="uploadFiles"></button>
      </template>
    </form-upload-files>
    <!-- Upload Message Error -->
    <div v-if="leadReports.uploadFileStatus === -1" class="px-4 pb-4 red--text">
      Erro ao tentar enviar os arquivos, tente novamente.
    </div>
    <!-- Upload Message Success -->
    <div class="px-4 pb-4 green--text">
      {{ uploadMessageSuccess }}
    </div>
    <!-- ACTIONS -->
    <v-card-actions class="pb-4 px-3">
      <v-layout justify-space-between>
        <!-- CANCEL UPLOAD -->
        <v-btn
          v-if="showUploadForm"
          small
          color="white"
          class="mr-4"
          @click="onCancelUploadForm"
        >
          Cancelar
        </v-btn>
        <!-- UPLOAD -->
        <v-btn
          v-if="leadReports.uploadFileStatus <= 0"
          small
          color="primary"
          @click="onUploadForm"
          ><v-icon class="white--text mr-1" size="22">mdi-upload</v-icon
          >Upload</v-btn
        >
        <!-- WAITING -->
        <div v-if="leadReports.uploadFileStatus === 1" class="px-1">
          <v-progress-circular
            indeterminate
            color="primary"
            width="2"
            size="20"
            class="mr-1"
          />
          <span>aguarde...</span>
        </div>
        <!-- CLOSE -->
        <v-btn
          v-if="!showUploadForm"
          small
          color="white"
          class="mr-4"
          @click="onClose"
        >
          Fechar
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import FormUploadFiles from "./form-upload-files";

export default {
  components: { FormUploadFiles },
  props: ["show", "item", "number"],
  model: {
    prop: "show",
    event: "change",
  },
  watch: {
    show(state) {
      if (state) {
        this.getFiles();
        this.$store.commit("dashboard/leadReports/setUploadFileStatus", 0);
      } else {
        setTimeout(() => {
          this.files = [];
          this.onCancelUploadForm();
        }, 100);
      }
    },
  },
  data: () => ({
    requestStatus: 0,
    downloadStatus: 0,
    files: [],
    showUploadForm: false,
    uploadMessageSuccess: "",
  }),
  computed: {
    leadReports() {
      return this.$store.state.dashboard.leadReports;
    },
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    onClose() {
      this.$emit("change", false);
    },
    getFiles() {
      this.requestStatus = 1;
      this.$store
        .dispatch("dashboard/leadReports/getFilesAffiliateBySolic", {
          solicId: this.number,
        })
        .then((data) => {
          this.files = data;
          this.requestStatus = 0;
        })
        .catch(() => (this.requestStatus = -1));
    },
    async download(id, filename, mimetype) {
      try {
        this.downloadStatus = 1;
        const data = await this.$store.dispatch(
          "dashboard/leadReports/downloadFileAffiliateBySolic",
          {
            id,
          }
        );

        window.download(data, filename, mimetype);
        this.downloadStatus = 0;
      } catch (err) {
        console.error(err);
        this.downloadStatus = -1;
      }
    },
    getSize(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
    getTextColor(mimetype) {
      const [type, ext] = mimetype.split("/");
      if (type === "image") {
        return "primary--text";
      } else if (ext === "pdf") {
        return "red--text";
      } else if (ext === "zip") {
        return "amber--text";
      } else {
        return "primary--text";
      }
    },
    getFileIcon(mimetype) {
      const [type, ext] = mimetype.split("/");
      if (type === "image") {
        return "mdi-file-image";
      } else {
        switch (ext) {
          case "pdf":
            return "mdi-file-pdf-box";
          case "doc":
          case "docx":
            return "mdi-microsoft-word";
          case "zip":
            return "mdi-folder-zip";
          default:
            return "mdi-file-document";
        }
      }
    },
    onUploadForm() {
      if (!this.showUploadForm) return (this.showUploadForm = true);
      this.$refs.btnUploadFilesRef.click();
    },
    onCancelUploadForm() {
      this.showUploadForm = false;
      this.$store.commit("dashboard/leadReports/setUploadFileStatus", 0);
    },
    afterUploadFiles(data) {
      this.getFiles();
      this.showUploadForm = false;
      this.uploadMessageSuccess = "Upload efetuado com sucesso!";
      setTimeout(() => (this.uploadMessageSuccess = ""), 2500);
      // muda o status_op sem precisar recarregar a página
      this.item.status_op = data.status_op;
      // muda o comentario_op sem precisar recarregar a página
      this.item.comentario_op = data.comment;
      this.$store.commit("setEmitEventHomeOnSearch", true);
    },
  },
};
</script>

<style lang="scss">
.data-view-documents {
  .btn-download {
    display: none;
  }

  .file-item:hover .btn-download {
    display: block;
  }
}
</style>
