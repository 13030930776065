<template>
  <div class="single-paginator">
    <v-row>
      <v-col>
        <v-btn icon @click="onPrevPage">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn icon @click="onNextPage">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    onNextPage() {
      location.href = location.pathname + `?page=${this.$route.query.page > 0 ? parseInt(this.$route.query.page) + 1 : 2}`
    },
    onPrevPage() {
      location.href = location.pathname + `?page=${this.$route.query.page > 1 ? parseInt(this.$route.query.page) - 1 : 1}`
    },
  }
}
</script>

<style scoped>

</style>
