<template>
  <v-row class="toolbar-section" no-gutters>
    <v-col sm="12">
      <small class="grey--text text--darken-1">{{ title }}</small>
    </v-col>
    <v-col sm="12">
      <v-layout>
        <slot />
      </v-layout>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['title', 'smCols']
}
</script>

<style scoped>

</style>
