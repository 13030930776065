

export const convertTable = (tableHTML, transparentBackground = false) => {
  const table = tableHTML
  let trans = '';
  if (transparentBackground) {
    trans = '';
  } else {
    trans = 'background-color:white;';
  }
  let xhtml = `
    <div xmlns="http://www.w3.org/1999/xhtml" style="${trans}">
      <style>
        table {
          border: 1px solid #333;
          width: 100%;
          height: 100%;
          border-collapse: collapse;
        }
        td {
          border: 1px solid #333;
        }
        td:nth-child(2n) {
          display: table-cell;
          text-align: center;
        }
      </style>
      ${table}
    </div>`
;
  return xhtml;
}